import { createContext, useContext, PropsWithChildren } from "react";
import useStorage, { UseStorageResult } from "../storage/useStorage";

export type UserInfo = string;

const UserInfoContext = createContext<UseStorageResult<UserInfo> | undefined>(
  undefined
);

export function UserInfoProvider({ children }: PropsWithChildren<{}>) {
  const value = useStorage<UserInfo>("sid");
  return (
    <UserInfoContext.Provider value={value}>
      {children}
    </UserInfoContext.Provider>
  );
}

export function useUserInfo() {
  const userInfo = useContext(UserInfoContext);
  if (userInfo === undefined) {
    throw new Error("useUserInfo must be used within an UserInfoContext");
  }
  return userInfo;
}
