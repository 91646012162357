import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  PropsWithChildren,
} from "react";
import { IntlProvider } from "react-intl";

import { useStorage } from "../storage";
import en_messages from "./en.json";
import hu_messages from "./hu.json";

const defaultLocale = "en";

const localizedMessages = {
  en: en_messages,
  hu: hu_messages,
};

type LocaleName = keyof typeof localizedMessages;
const locales = Object.keys(localizedMessages) as LocaleName[];

function mapLocale(locale: unknown): LocaleName {
  if (typeof locale !== "string") {
    return defaultLocale;
  }

  const validLocales = Object.keys(localizedMessages);
  if (validLocales.includes(locale)) {
    return locale as LocaleName;
  }

  const splitLocale = locale.split("-")[0].toLocaleLowerCase();
  if (validLocales.includes(splitLocale)) {
    return splitLocale as LocaleName;
  }

  return defaultLocale;
}

export interface Locale {
  locale: LocaleName;
  locales: LocaleName[];
  setLocale: (locale: string) => void;
}

const LocaleContext = createContext<Locale | undefined>(undefined);

export const useLocale = () => {
  const value = useContext(LocaleContext);
  if (value === undefined) {
    throw new Error("useLocale must be used inside LocaleProvider");
  }
  return value;
};

export default function LocaleProvider({ children }: PropsWithChildren<{}>) {
  const [_locale, _setLocale] = useStorage<unknown>(
    "locale",
    navigator.language
  );

  const locale = mapLocale(_locale);
  const setLocale = useCallback(
    (locale) => {
      _setLocale(mapLocale(locale));
    },
    [_setLocale]
  );

  const value = useMemo(() => ({ locale, setLocale, locales }), [
    locale,
    setLocale,
  ]);

  return (
    <LocaleContext.Provider value={value}>
      <IntlProvider
        locale={locale}
        messages={localizedMessages[locale]}
        defaultLocale={defaultLocale}
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
}
