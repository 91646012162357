import { PropsWithChildren } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  RouteProps,
  Redirect,
} from "react-router-dom";
import loadable from "@loadable/component";
import { useIntl } from "react-intl";
import { ThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { QueryClient, QueryClientProvider } from "react-query";
import { muiTheme } from "@mb-pro-ui/components";
import LocaleProvider from "./locales/LocaleProvider";
import { AuthProvider, useAuth } from "./auth";
import { UserInfoProvider } from "./state/user-info";
import { ApiProvider } from "./query";
import { messages as legacyMessages } from "./locales/legacy/en";
import LoginPage from "./pages/LoginPage";

const Loading = () => {
  const intl = useIntl();
  return (
    <div>{intl.formatMessage(legacyMessages["alarm.patrolling.loading"])}</div>
  );
};

const LoadableHomePage = loadable(() => import("./pages/HomePage"), {
  fallback: <Loading />,
});

const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const queryClient = new QueryClient();

const Providers = ({ children }: PropsWithChildren<{}>) => {
  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <UserInfoProvider>
        <ApiProvider baseUrl="/api">
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <LocaleProvider>{children}</LocaleProvider>
            </AuthProvider>
          </QueryClientProvider>
        </ApiProvider>
      </UserInfoProvider>
    </ThemeProvider>
  );
};

const AppContent = () => {
  return (
    <>
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <PrivateRoute path="/home">
          <LoadableHomePage />
        </PrivateRoute>
        <Route>
          <Redirect to="/home" />
        </Route>
      </Switch>
    </>
  );
};

export default function App() {
  return (
    <Providers>
      <Router basename="/">
        <AppContent />
      </Router>
    </Providers>
  );
}
