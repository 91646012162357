export interface HttpError extends Error {
  status?: number;
  type?: string;
  data?: unknown;
}

export async function http<T = unknown>(
  path: string,
  options?: RequestInit
): Promise<T> {
  const response = await fetch(path, options);

  const type = response.headers.get("Content-Type");
  let data: unknown;
  if (type?.match(/text\/plain/)) {
    data = await response.text();
  } else if (type?.match(/[/+]json$/)) {
    data = await response.json();
  } else {
    data = await response.blob();
  }

  if (!response.ok) {
    throw Object.assign(
      new Error(`HTTP Error: ${response.status} ${response.statusText}`),
      { status: response.status, type, data }
    );
  }

  return data as T;
}

export function httpWithAuth<T = unknown>(
  token: string,
  path: string,
  options?: RequestInit
) {
  const headers = new Headers(options?.headers);
  headers.set("Authorization", `Bearer ${token}`);
  if (!options) {
    options = { headers };
  } else {
    options.headers = headers;
  }
  return http<T>(path, options);
}
