import { useCallback, useEffect, useState } from 'react';
import appStorage from "./app-storage";

export type UseStorageResult<T> = [value: T | null, setValue: (value: T | null) => void];

export default function useStorage<T>(key: string, defaultValue: T): [value: T, setValue: (value: T | null) => void];
export default function useStorage<T>(key: string): UseStorageResult<T>;

export default function useStorage<T>(key: string, defaultValue?: T): UseStorageResult<T> {
  const [value, _setValue] = useState<T | null>(appStorage.get<T>(key) ?? defaultValue ?? null);

  useEffect(() => {
    function handleStorageChange() {
      _setValue(appStorage.get<T>(key));
    }

    appStorage.on(handleStorageChange);
    return () => { appStorage.off(handleStorageChange) };
  }, [key, _setValue]);

  const setValue = useCallback(
    (newValue) => {
      if (newValue === null) {
        appStorage.remove(key);
      } else {
        appStorage.set(key, newValue);
      }
      appStorage.save();
      _setValue(newValue);
    },
    [key, _setValue]
  );

  return [value, setValue];
}
