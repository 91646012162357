import { defineMessages } from "react-intl";

export const messages = defineMessages({
  "404": {
    id: "404",
    defaultMessage: "Page not found",
  },
  "descr-locale": {
    id: "descr-locale",
    defaultMessage: "en-gb",
  },
  cancel: {
    id: "cancel",
    defaultMessage: "Cancel",
  },
  save: {
    id: "save",
    defaultMessage: "Save",
  },
  add: {
    id: "add",
    defaultMessage: "Add",
  },
  "gps.vehicles": {
    id: "gps.vehicles",
    defaultMessage: "Vehicles",
  },
  "gps.vehicles.edit-title": {
    id: "gps.vehicles.edit-title",
    defaultMessage: "Edit vehicle",
  },
  "gps.vehicles.new-title": {
    id: "gps.vehicles.new-title",
    defaultMessage: "New vehicle",
  },
  "gps.vehicle-groups.edit-title": {
    id: "gps.vehicle-groups.edit-title",
    defaultMessage: "Edit vehicle group",
  },
  "gps.vehicle-groups.new-title": {
    id: "gps.vehicle-groups.new-title",
    defaultMessage: "New vehicle group",
  },
  "gps.units.edit-title": {
    id: "gps.units.edit-title",
    defaultMessage: "Edit unit",
  },
  "gps.units.new-title": {
    id: "gps.units.new-title",
    defaultMessage: "New unit",
  },
  "gps.drivers.edit-title": {
    id: "gps.drivers.edit-title",
    defaultMessage: "Edit driver",
  },
  "gps.drivers.new-title": {
    id: "gps.drivers.new-title",
    defaultMessage: "New driver",
  },
  "gps.driver-groups.edit-title": {
    id: "gps.driver-groups.edit-title",
    defaultMessage: "Edit driver group",
  },
  "gps.driver-groups.new-title": {
    id: "gps.driver-groups.new-title",
    defaultMessage: "New driver group",
  },
  "gps.users.edit-title": {
    id: "gps.users.edit-title",
    defaultMessage: "Edit user",
  },
  "gps.users.new-title": {
    id: "gps.users.new-title",
    defaultMessage: "New user",
  },
  "gps.user-groups.edit-title": {
    id: "gps.user-groups.edit-title",
    defaultMessage: "Edit user group",
  },
  "gps.user-groups.new-title": {
    id: "gps.user-groups.new-title",
    defaultMessage: "New user group",
  },
  "gps.companies.edit-title": {
    id: "gps.companies.edit-title",
    defaultMessage: "Edit company",
  },
  "gps.companies.new-title": {
    id: "gps.companies.new-title",
    defaultMessage: "New company",
  },
  "gps.company-groups.edit-title": {
    id: "gps.company-groups.edit-title",
    defaultMessage: "Edit company group",
  },
  "gps.company-groups.new-title": {
    id: "gps.company-groups.new-title",
    defaultMessage: "New company group",
  },
  "gps.icons.edit-title": {
    id: "gps.icons.edit-title",
    defaultMessage: "Edit icon",
  },
  "gps.icons.new-title": {
    id: "gps.icons.new-title",
    defaultMessage: "New icon",
  },
  "gps.pois.edit-title": {
    id: "gps.pois.edit-title",
    defaultMessage: "Edit area",
  },
  "gps.pois.new-title": {
    id: "gps.pois.new-title",
    defaultMessage: "New area",
  },
  "gps.mapprofiles.edit-title": {
    id: "gps.mapprofiles.edit-title",
    defaultMessage: "Edit map profile",
  },
  "gps.mapprofile.active-icon": {
    id: "gps.mapprofile.active-icon",
    defaultMessage: "Icon when moving",
  },
  "gps.mapprofile.inactive-icon": {
    id: "gps.mapprofile.inactive-icon",
    defaultMessage: "Icon when stopped",
  },
  "gps.mapprofile.icon-rotate": {
    id: "gps.mapprofile.icon-rotate",
    defaultMessage: "Indicate heading",
  },
  "gps.mapprofile.line-thickness": {
    id: "gps.mapprofile.line-thickness",
    defaultMessage: "Line thickness",
  },
  "gps.mapprofile.line-style": {
    id: "gps.mapprofile.line-style",
    defaultMessage: "Line style",
  },
  "gps.mapprofile.icon-spacing": {
    id: "gps.mapprofile.icon-spacing",
    defaultMessage: "Distance between icons",
  },
  "gps.poi.outlines": {
    id: "gps.poi.outlines",
    defaultMessage: "Outlines",
  },
  "gps.poi.line-thickness": {
    id: "gps.poi.line-thickness",
    defaultMessage: "Line thickness",
  },
  "gps.poi.fill": {
    id: "gps.poi.fill",
    defaultMessage: "Fill",
  },
  "gps.ugroup.filter-criteria": {
    id: "gps.ugroup.filter-criteria",
    defaultMessage: "Filter-criteria",
  },
  "gps.ugroup.filter.company.all": {
    id: "gps.ugroup.filter.company.all",
    defaultMessage: "All companies",
  },
  "gps.ugroup.filter.company.group": {
    id: "gps.ugroup.filter.company.group",
    defaultMessage: "All companies of a group",
  },
  "gps.ugroup.filter.company.single": {
    id: "gps.ugroup.filter.company.single",
    defaultMessage: "A specific company",
  },
  "gps.ugroup.filter.company.own-group": {
    id: "gps.ugroup.filter.company.own-group",
    defaultMessage: "All companies of own group",
  },
  "gps.ugroup.filter.company.own": {
    id: "gps.ugroup.filter.company.own",
    defaultMessage: "Own company",
  },
  "gps.ugroup.filter.vehicle.all": {
    id: "gps.ugroup.filter.vehicle.all",
    defaultMessage: "All vehicles",
  },
  "gps.ugroup.filter.vehicle.group": {
    id: "gps.ugroup.filter.vehicle.group",
    defaultMessage: "All vehicles of a group",
  },
  "gps.ugroup.filter.vehicle.single": {
    id: "gps.ugroup.filter.vehicle.single",
    defaultMessage: "A specific vehicle",
  },
  "gps.ugroup.permissions.view": {
    id: "gps.ugroup.permissions.view",
    defaultMessage: "View",
  },
  "gps.ugroup.permissions.edit": {
    id: "gps.ugroup.permissions.edit",
    defaultMessage: "Edit",
  },
  "gps.ugroup.permissions.vehicles": {
    id: "gps.ugroup.permissions.vehicles",
    defaultMessage: "Vehicle administration",
  },
  "gps.ugroup.permissions.vgroups": {
    id: "gps.ugroup.permissions.vgroups",
    defaultMessage: "Vehicle group administration",
  },
  "gps.ugroup.permissions.units": {
    id: "gps.ugroup.permissions.units",
    defaultMessage: "Unit administration",
  },
  "gps.ugroup.permissions.drivers": {
    id: "gps.ugroup.permissions.drivers",
    defaultMessage: "Driver administration",
  },
  "gps.ugroup.permissions.dgroups": {
    id: "gps.ugroup.permissions.dgroups",
    defaultMessage: "Driver group administration",
  },
  "gps.ugroup.permissions.users": {
    id: "gps.ugroup.permissions.users",
    defaultMessage: "User administration",
  },
  "gps.ugroup.permissions.ugroups": {
    id: "gps.ugroup.permissions.ugroups",
    defaultMessage: "User group administration",
  },
  "gps.ugroup.permissions.companies": {
    id: "gps.ugroup.permissions.companies",
    defaultMessage: "Company administration",
  },
  "gps.ugroup.permissions.cgroups": {
    id: "gps.ugroup.permissions.cgroups",
    defaultMessage: "Company group administration",
  },
  "gps.ugroup.permissions.gps": {
    id: "gps.ugroup.permissions.gps",
    defaultMessage: "GPS tab",
  },
  "gps.ugroup.permissions.measurements": {
    id: "gps.ugroup.permissions.measurements",
    defaultMessage: "Measurements tab",
  },
  "gps.ugroup.permissions.status": {
    id: "gps.ugroup.permissions.status",
    defaultMessage: "Status tab",
  },
  "gps.user.ugroup": {
    id: "gps.user.ugroup",
    defaultMessage: "User group",
  },
  "gps.user.login": {
    id: "gps.user.login",
    defaultMessage: "Login name",
  },
  "gps.user.company-filter-settings": {
    id: "gps.user.company-filter-settings",
    defaultMessage: "Company and user group settings give empty list.",
  },
  "gps.unit.installed": {
    id: "gps.unit.installed",
    defaultMessage: "Date of installation",
  },
  "gps.unit.last-update": {
    id: "gps.unit.last-update",
    defaultMessage: "Date od last refresh",
  },
  "gps.unit.software-version": {
    id: "gps.unit.software-version",
    defaultMessage: "Software version",
  },
  "gps.unit.hardware-version": {
    id: "gps.unit.hardware-version",
    defaultMessage: "Hardware version",
  },
  "gps.unit.mapprofile": {
    id: "gps.unit.mapprofile",
    defaultMessage: "Map profile",
  },
  "gps.unit.movement-detection": {
    id: "gps.unit.movement-detection",
    defaultMessage: "Movement detection",
  },
  "gps.unit.ignition": {
    id: "gps.unit.ignition",
    defaultMessage: "Ignition",
  },
  "gps.vehicle.regnr": {
    id: "gps.vehicle.regnr",
    defaultMessage: "Registration number",
  },
  "gps.vehicle.current-driver": {
    id: "gps.vehicle.current-driver",
    defaultMessage: "Current driver",
  },
  "gps.vehicle.fuel-consumation": {
    id: "gps.vehicle.fuel-consumation",
    defaultMessage: "Average fuel economy",
  },
  "gps.vehicle.remote-pass": {
    id: "gps.vehicle.remote-pass",
    defaultMessage: "Android password",
  },
  "gps.vehicle.vgroup-memberships": {
    id: "gps.vehicle.vgroup-memberships",
    defaultMessage: "Vehicle group memberships",
  },
  "gps.vehicle.fuel-tank": {
    id: "gps.vehicle.fuel-tank",
    defaultMessage: "Fuel tank",
  },
  "gps.vehicle.dual-tank": {
    id: "gps.vehicle.dual-tank",
    defaultMessage: "Dual tank",
  },
  "gps.vehicle.channel": {
    id: "gps.vehicle.channel",
    defaultMessage: "Channel",
  },
  "gps.vehicle.calibration": {
    id: "gps.vehicle.calibration",
    defaultMessage: "Fuel level calibration",
  },
  "gps.vehicle.lower-calibration-point": {
    id: "gps.vehicle.lower-calibration-point",
    defaultMessage: "Lower calibration point",
  },
  "gps.vehicle.higher-calibration-point": {
    id: "gps.vehicle.higher-calibration-point",
    defaultMessage: "Higher calibration point",
  },
  "gps.vehicle.calibration-value": {
    id: "gps.vehicle.calibration-value",
    defaultMessage: "Analogue value measured",
  },
  "gps.vehicle.calibration-level": {
    id: "gps.vehicle.calibration-level",
    defaultMessage: "Amount of fuel",
  },
  "gps.vehicle.data-source": {
    id: "gps.vehicle.data-source",
    defaultMessage: "Data source",
  },
  "gps.vehicle.fuel-level-change": {
    id: "gps.vehicle.fuel-level-change",
    defaultMessage: "Fuel level change",
  },
  "gps.vehicle.flow-sensor-data": {
    id: "gps.vehicle.flow-sensor-data",
    defaultMessage: "Flow sensor data",
  },
  "gps.vehicle.flow-sensor-parameters": {
    id: "gps.vehicle.flow-sensor-parameters",
    defaultMessage: "Flow sensor parameters",
  },
  "gps.vehicle.fuel-consumation-pulses": {
    id: "gps.vehicle.fuel-consumation-pulses",
    defaultMessage: "Count per 1 liter consumed",
  },
  "gps.vehicle.impulses": {
    id: "gps.vehicle.impulses",
    defaultMessage: "impulses",
  },
  "gps.vehicle.superstructure": {
    id: "gps.vehicle.superstructure",
    defaultMessage: "Superstructure",
  },
  "gps.vehicle.working-signal-channel": {
    id: "gps.vehicle.working-signal-channel",
    defaultMessage: "Working signal channel",
  },
  "gps.vehicle.odometer": {
    id: "gps.vehicle.odometer",
    defaultMessage: "Odometer",
  },
  "gps.vehicle.current-value": {
    id: "gps.vehicle.current-value",
    defaultMessage: "Current value",
  },
  "gps.vehicle.synchronization": {
    id: "gps.vehicle.synchronization",
    defaultMessage: "Synchronization with odometer",
  },
  "gps.vehicle.last-correction": {
    id: "gps.vehicle.last-correction",
    defaultMessage: "Last correction",
  },
  "gps.vehicle.corrected-value": {
    id: "gps.vehicle.corrected-value",
    defaultMessage: "Corrected value",
  },
  "gps.vehicle.correct": {
    id: "gps.vehicle.correct",
    defaultMessage: "Correct",
  },
  "gps.vehicle.monthly-meters": {
    id: "gps.vehicle.monthly-meters",
    defaultMessage: "Distance covered this month",
  },
  "gps.vehicle.total-meters": {
    id: "gps.vehicle.total-meters",
    defaultMessage: "Total distance covered",
  },
  "gps.position-data.set-filter": {
    id: "gps.position-data.set-filter",
    defaultMessage: "Select filter options",
  },
  "gps.position-data.overview": {
    id: "gps.position-data.overview",
    defaultMessage: "Overview",
  },
  "gps.position-data.zoom": {
    id: "gps.position-data.zoom",
    defaultMessage: "Zoom",
  },
  "gps.position-data.search": {
    id: "gps.position-data.search",
    defaultMessage: "Search",
  },
  "gps.position-data.chart.speed": {
    id: "gps.position-data.chart.speed",
    defaultMessage: "Speed (km/h)",
  },
  "gps.position-data.chart.fuel-level": {
    id: "gps.position-data.chart.fuel-level",
    defaultMessage: "Fuel level (liter)",
  },
  "gps.position-data.chart.fuel-consumation": {
    id: "gps.position-data.chart.fuel-consumation",
    defaultMessage: "Fuel consumation",
  },
  "gps.position-data.measurements": {
    id: "gps.position-data.measurements",
    defaultMessage: "Measurements",
  },
  "gps.position-data.tachograph": {
    id: "gps.position-data.tachograph",
    defaultMessage: "Tachograph",
  },
  "gps.position-data.fuel-level": {
    id: "gps.position-data.fuel-level",
    defaultMessage: "Fuel level",
  },
  "gps.position-data.table.id": {
    id: "gps.position-data.table.id",
    defaultMessage: "Nr",
  },
  "gps.position-data.table.timestamp": {
    id: "gps.position-data.table.timestamp",
    defaultMessage: "Date, time",
  },
  "gps.position-data.table.altitude": {
    id: "gps.position-data.table.altitude",
    defaultMessage: "Altitude",
  },
  "gps.position-data.table.course": {
    id: "gps.position-data.table.course",
    defaultMessage: "Direction",
  },
  "gps.position-data.table.speed": {
    id: "gps.position-data.table.speed",
    defaultMessage: "Speed",
  },
  "gps.position-data.table.meter-count": {
    id: "gps.position-data.table.meter-count",
    defaultMessage: "Odometer",
  },
  "gps.position-data.table.corrected-meter-count": {
    id: "gps.position-data.table.corrected-meter-count",
    defaultMessage: "Odometer, corrected",
  },
  "gps.position-data.table.vsat": {
    id: "gps.position-data.table.vsat",
    defaultMessage: "Satellites",
  },
  "gps.position-data.table.position-accuracy": {
    id: "gps.position-data.table.position-accuracy",
    defaultMessage: "Precision",
  },
  "gps.position-data.table.analog": {
    id: "gps.position-data.table.analog",
    defaultMessage: "Analogue #{nr}",
  },
  "gps.position-data.table.digital-in": {
    id: "gps.position-data.table.digital-in",
    defaultMessage: "Digital input",
  },
  "gps.position-data.table.voltage": {
    id: "gps.position-data.table.voltage",
    defaultMessage: "Battery voltage",
  },
  "gps.position-data.table.system-temperature": {
    id: "gps.position-data.table.system-temperature",
    defaultMessage: "System temperature",
  },
  "gps.position-data.table.text-message": {
    id: "gps.position-data.table.text-message",
    defaultMessage: "Text Message",
  },
  "gps.position-data.table.provider-type": {
    id: "gps.position-data.table.provider-type",
    defaultMessage: "Provider",
  },
  "gps.position-data.table.vehicle-status": {
    id: "gps.position-data.table.vehicle-status",
    defaultMessage: "Vehicle status",
  },
  "gps.position-data.table.unit-mode": {
    id: "gps.position-data.table.unit-mode",
    defaultMessage: "Unit mode",
  },
  "gps.position-data.table.event-info": {
    id: "gps.position-data.table.event-info",
    defaultMessage: "Event info",
  },
  "gps.position-data.table.last-request-timestamp": {
    id: "gps.position-data.table.last-request-timestamp",
    defaultMessage: "Config timestamp",
  },
  "gps.position-data.table.data-timestamp": {
    id: "gps.position-data.table.data-timestamp",
    defaultMessage: "Data received",
  },
  "gps.position-data.table.processed-timestamp": {
    id: "gps.position-data.table.processed-timestamp",
    defaultMessage: "Processed timestamp",
  },
  "gps.position-data.table.driver-code": {
    id: "gps.position-data.table.driver-code",
    defaultMessage: "Driver code",
  },
  "gps.position-data.table.hardware-version": {
    id: "gps.position-data.table.hardware-version",
    defaultMessage: "HW",
  },
  "gps.position-data.table.software-version": {
    id: "gps.position-data.table.software-version",
    defaultMessage: "SW",
  },
  "gps.position-data.table.usage-type": {
    id: "gps.position-data.table.usage-type",
    defaultMessage: "Usage",
  },
  "gps.position-data.table.driver": {
    id: "gps.position-data.table.driver",
    defaultMessage: "Driver ID",
  },
  "gps.position-data.table.fuel": {
    id: "gps.position-data.table.fuel",
    defaultMessage: "Amount",
  },
  "gps.position-data.table.date-range": {
    id: "gps.position-data.table.date-range",
    defaultMessage: "Date range",
  },
  "gps.position-data.table.consumption": {
    id: "gps.position-data.table.consumption",
    defaultMessage: "Consumption",
  },
  "gps.position-data.home": {
    id: "gps.position-data.home",
    defaultMessage: "Home",
  },
  "gps.position-data.abroad": {
    id: "gps.position-data.abroad",
    defaultMessage: "Abroad",
  },
  "gps.position-data.total": {
    id: "gps.position-data.total",
    defaultMessage: "Total",
  },
  "gps.position-data.resolutions.hour": {
    id: "gps.position-data.resolutions.hour",
    defaultMessage: "Per hour",
  },
  "gps.position-data.resolutions.day": {
    id: "gps.position-data.resolutions.day",
    defaultMessage: "Daily",
  },
  "gps.position-data.resolutions.month": {
    id: "gps.position-data.resolutions.month",
    defaultMessage: "Per month",
  },
  "gps.position-data.state.stop": {
    id: "gps.position-data.state.stop",
    defaultMessage: "Stopped",
  },
  "gps.position-data.state.traffic": {
    id: "gps.position-data.state.traffic",
    defaultMessage: "In traffic",
  },
  "gps.position-data.state.pause": {
    id: "gps.position-data.state.pause",
    defaultMessage: "Driver resting",
  },
  "gps.position-data.state.lunch": {
    id: "gps.position-data.state.lunch",
    defaultMessage: "Driver eating",
  },
  "gps.position-data.state.sleep": {
    id: "gps.position-data.state.sleep",
    defaultMessage: "Driver Sleeping",
  },
  "gps.position-data.state.border": {
    id: "gps.position-data.state.border",
    defaultMessage: "Waiting at border",
  },
  "gps.position-data.state.tank": {
    id: "gps.position-data.state.tank",
    defaultMessage: "Fueling",
  },
  "gps.position-data.state.load": {
    id: "gps.position-data.state.load",
    defaultMessage: "Loading",
  },
  "gps.position-data.state.free": {
    id: "gps.position-data.state.free",
    defaultMessage: "Free",
  },
  "gps.position-data.mode.disabled": {
    id: "gps.position-data.mode.disabled",
    defaultMessage: "Disabled",
  },
  "gps.position-data.mode.quiet": {
    id: "gps.position-data.mode.quiet",
    defaultMessage: "Quiet",
  },
  "gps.position-data.mode.minimal": {
    id: "gps.position-data.mode.minimal",
    defaultMessage: "Minimal",
  },
  "gps.position-data.mode.normal": {
    id: "gps.position-data.mode.normal",
    defaultMessage: "Normal",
  },
  "gps.position-data.mode.standalone": {
    id: "gps.position-data.mode.standalone",
    defaultMessage: "Standalone",
  },
  "gps.position-data.event.gps-move": {
    id: "gps.position-data.event.gps-move",
    defaultMessage: "Movement",
  },
  "gps.position-data.event.gps-turn": {
    id: "gps.position-data.event.gps-turn",
    defaultMessage: "Turn",
  },
  "gps.position-data.event.timed": {
    id: "gps.position-data.event.timed",
    defaultMessage: "Timed",
  },
  "gps.position-data.event.reply": {
    id: "gps.position-data.event.reply",
    defaultMessage: "Reply",
  },
  "gps.position-data.event.gps-lost": {
    id: "gps.position-data.event.gps-lost",
    defaultMessage: "GPS signal lost",
  },
  "gps.position-data.event.gps-found": {
    id: "gps.position-data.event.gps-found",
    defaultMessage: "GPS signal found",
  },
  "gps.position-data.event.gsm-network": {
    id: "gps.position-data.event.gsm-network",
    defaultMessage: "GSM network event",
  },
  "gps.position-data.event.vehicle-park": {
    id: "gps.position-data.event.vehicle-park",
    defaultMessage: "Vehicle stopped",
  },
  "gps.position-data.event.vehicle-lifesign": {
    id: "gps.position-data.event.vehicle-lifesign",
    defaultMessage: "Vehicle lifesign",
  },
  "gps.position-data.event.vehicle-traffic": {
    id: "gps.position-data.event.vehicle-traffic",
    defaultMessage: "Vehicle started",
  },
  "gps.position-data.event.rfid-login": {
    id: "gps.position-data.event.rfid-login",
    defaultMessage: "RFID login",
  },
  "gps.position-data.event.rfid-logout": {
    id: "gps.position-data.event.rfid-logout",
    defaultMessage: "RFID logout",
  },
  "gps.position-data.event.gsm-lost": {
    id: "gps.position-data.event.gsm-lost",
    defaultMessage: "GSM signal lost",
  },
  "gps.position-data.event.gsm-found": {
    id: "gps.position-data.event.gsm-found",
    defaultMessage: "GSM signal found",
  },
  "gps.position-data.event.ignition-on": {
    id: "gps.position-data.event.ignition-on",
    defaultMessage: "Ignition on",
  },
  "gps.position-data.event.ignition-off": {
    id: "gps.position-data.event.ignition-off",
    defaultMessage: "Ignition off",
  },
  "gps.position-data.event.poly-leave": {
    id: "gps.position-data.event.poly-leave",
    defaultMessage: "Leaving area",
  },
  "gps.position-data.event.poly-arrive": {
    id: "gps.position-data.event.poly-arrive",
    defaultMessage: "Entering area",
  },
  "gps.position-data.event.digital-in-0-15": {
    id: "gps.position-data.event.digital-in-0-15",
    defaultMessage: "Dig. in 0-15 event",
  },
  "gps.position-data.event.digital-in-16-31": {
    id: "gps.position-data.event.digital-in-16-31",
    defaultMessage: "Dig. in 16-31 event",
  },
  "gps.position-data.event.analogue": {
    id: "gps.position-data.event.analogue",
    defaultMessage: "Analogue input event",
  },
  "gps.position-data.event.digital-out-off": {
    id: "gps.position-data.event.digital-out-off",
    defaultMessage: "Digital out off",
  },
  "gps.position-data.event.digital-out-on": {
    id: "gps.position-data.event.digital-out-on",
    defaultMessage: "Digital out on",
  },
  "gps.position-data.event.ignition-lock-on": {
    id: "gps.position-data.event.ignition-lock-on",
    defaultMessage: "Ignition lock on",
  },
  "gps.position-data.event.ignition-lock-off": {
    id: "gps.position-data.event.ignition-lock-off",
    defaultMessage: "Ignition lock off",
  },
  "gps.waybill-statistic.duration.day": {
    id: "gps.waybill-statistic.duration.day",
    defaultMessage: "d[ {days, plural, =0 {} one {day} other {days}}]",
  },
  "gps.waybill-statistic.duration.daytime": {
    id: "gps.waybill-statistic.duration.daytime",
    defaultMessage: "d[ {days, plural, =0 {} one {day} other {days}}] HH:mm:ss",
  },
  "gps.waybills.advanced-search": {
    id: "gps.waybills.advanced-search",
    defaultMessage: "Advanced search",
  },
  "gps.waybills.from-date": {
    id: "gps.waybills.from-date",
    defaultMessage: "From date",
  },
  "gps.waybills.to-date": {
    id: "gps.waybills.to-date",
    defaultMessage: "To date",
  },
  "gps.waybills.simple-search": {
    id: "gps.waybills.simple-search",
    defaultMessage: "Simple Search",
  },
  "gps.waybills.unit": {
    id: "gps.waybills.unit",
    defaultMessage: "Unit",
  },
  "gps.waybills.trip-type": {
    id: "gps.waybills.trip-type",
    defaultMessage: "Trip type",
  },
  "gps.waybills.dgroup": {
    id: "gps.waybills.dgroup",
    defaultMessage: "Driver group",
  },
  "gps.waybills.run-query": {
    id: "gps.waybills.run-query",
    defaultMessage: "Run query",
  },
  "gps.waybills.stops": {
    id: "gps.waybills.stops",
    defaultMessage: "Stops",
  },
  "gps.waybills.statistics": {
    id: "gps.waybills.statistics",
    defaultMessage: "Statistics",
  },
  "gps.waybills.no-data": {
    id: "gps.waybills.no-data",
    defaultMessage: "There is no data to display.",
  },
  "gps.waybills.search": {
    id: "gps.waybills.search",
    defaultMessage: "Search",
  },
  "gps.waybills.no-results": {
    id: "gps.waybills.no-results",
    defaultMessage: "No results",
  },
  "gps.waybills.update-query": {
    id: "gps.waybills.update-query",
    defaultMessage: "Change search criteria",
  },
  "gps.waybills.too-many-results": {
    id: "gps.waybills.too-many-results",
    defaultMessage: "Too many results. Please refine the search conditions.",
  },
  "gps.waybills.downloading": {
    id: "gps.waybills.downloading",
    defaultMessage: "Downloading...",
  },
  "gps.waybills.chart": {
    id: "gps.waybills.chart",
    defaultMessage: "Chart",
  },
  "gps.waybills.textual": {
    id: "gps.waybills.textual",
    defaultMessage: "Textual",
  },
  "gps.waybills.sections.timestamp": {
    id: "gps.waybills.sections.timestamp",
    defaultMessage: "Timestamp",
  },
  "gps.waybills.chart.distance.vaxis": {
    id: "gps.waybills.chart.distance.vaxis",
    defaultMessage: "Distance covered (km)",
  },
  "gps.waybills.chart.statistics.vaxis": {
    id: "gps.waybills.chart.statistics.vaxis",
    defaultMessage: "Active time (%)",
  },
  "gps.waybills.day": {
    id: "gps.waybills.day",
    defaultMessage: "Day",
  },
  "gps.driver.document-number": {
    id: "gps.driver.document-number",
    defaultMessage: "Document number",
  },
  "gps.driver.dgroup-memberships": {
    id: "gps.driver.dgroup-memberships",
    defaultMessage: "Driver group membership",
  },
  "gps.driver.rfids": {
    id: "gps.driver.rfids",
    defaultMessage: "RFID keys",
  },
  "gps.driver.businnes": {
    id: "gps.driver.businnes",
    defaultMessage: "Business",
  },
  "gps.driver.private": {
    id: "gps.driver.private",
    defaultMessage: "Private",
  },
  "gps.company.police": {
    id: "gps.company.police",
    defaultMessage: "Police",
  },
  "gps.company.insurance": {
    id: "gps.company.insurance",
    defaultMessage: "Insurance company",
  },
  "mbp-models.id": {
    id: "mbp-models.id",
    defaultMessage: "Id",
  },
  "mbp-models.gps-drivers.phone": {
    id: "mbp-models.gps-drivers.phone",
    defaultMessage: "Phone",
  },
  "mbp-models.gps-vehicles.registration-number": {
    id: "mbp-models.gps-vehicles.registration-number",
    defaultMessage: "Registration Number",
  },
  "mbp-models.gps-vehicles.owner": {
    id: "mbp-models.gps-vehicles.owner",
    defaultMessage: "Owner",
  },
  "mbp-models.simple-groups.group-name": {
    id: "mbp-models.simple-groups.group-name",
    defaultMessage: "Group name",
  },
  "mbp-models.cdec.sent": {
    id: "mbp-models.cdec.sent",
    defaultMessage: "Sent",
  },
  "mbp-models.cdec.description": {
    id: "mbp-models.cdec.description",
    defaultMessage: "Event description",
  },
  "mbp-models.cdec.coordinates": {
    id: "mbp-models.cdec.coordinates",
    defaultMessage: "Coordinates",
  },
  "mbp-models.cdec.partition-number": {
    id: "mbp-models.cdec.partition-number",
    defaultMessage: "Partition",
  },
  "mbp-models.cdec.zone-number": {
    id: "mbp-models.cdec.zone-number",
    defaultMessage: "Zone/user/token",
  },
  "mbp-models.cdec.unknown": {
    id: "mbp-models.cdec.unknown",
    defaultMessage: "Unknown",
  },
  "mbp-models.cdec.cdec-filter": {
    id: "mbp-models.cdec.cdec-filter",
    defaultMessage: "Customer events",
  },
  "mbp-models.cdec.disable-event-code": {
    id: "mbp-models.cdec.disable-event-code",
    defaultMessage: "Disable event",
  },
  "mbp-models.fix-beacons.map-sector": {
    id: "mbp-models.fix-beacons.map-sector",
    defaultMessage: "Map sector",
  },
  "mbp-models.customers.customer-statistics": {
    id: "mbp-models.customers.customer-statistics",
    defaultMessage: "Customer statistics",
  },
  "mbp-models.categories.intervention-needed": {
    id: "mbp-models.categories.intervention-needed",
    defaultMessage: "Alert",
  },
  "mbp-models.interventions.start-time": {
    id: "mbp-models.interventions.start-time",
    defaultMessage: "Start time",
  },
  "mbp-models.interventions.close-time": {
    id: "mbp-models.interventions.close-time",
    defaultMessage: "Close time",
  },
  "mbp-models.firesignals.tfk-id": {
    id: "mbp-models.firesignals.tfk-id",
    defaultMessage: "TFK ID",
  },
  "mbp-models.firesignals.zip-code": {
    id: "mbp-models.firesignals.zip-code",
    defaultMessage: "Zip code",
  },
  "mbp-models.firesignals.city": {
    id: "mbp-models.firesignals.city",
    defaultMessage: "City",
  },
  "mbp-models.firesignals.api-key": {
    id: "mbp-models.firesignals.api-key",
    defaultMessage: "API key",
  },
  "mbp-models.rfid-tokens.customer-name": {
    id: "mbp-models.rfid-tokens.customer-name",
    defaultMessage: "Customer name",
  },
  "mbp-models.customer-groups.customer-count": {
    id: "mbp-models.customer-groups.customer-count",
    defaultMessage: "Device count",
  },
  "mbp-models.map-profiles.base-icon": {
    id: "mbp-models.map-profiles.base-icon",
    defaultMessage: "Base",
  },
  "mbp-models.map-profiles.alarm-icon": {
    id: "mbp-models.map-profiles.alarm-icon",
    defaultMessage: "Alarm",
  },
  "mbp-models.last-sectors.last-update": {
    id: "mbp-models.last-sectors.last-update",
    defaultMessage: "Last update",
  },
  "mbp-models.last-sectors.object-id": {
    id: "mbp-models.last-sectors.object-id",
    defaultMessage: "Sector object ID",
  },
  "mbp-models.last-sectors.prev-object-id": {
    id: "mbp-models.last-sectors.prev-object-id",
    defaultMessage: "Previous sector object ID",
  },
  "mbp-models.last-sectors.prev-number": {
    id: "mbp-models.last-sectors.prev-number",
    defaultMessage: "Previous sector number",
  },
  "mbp-models.last-sectors.last-change": {
    id: "mbp-models.last-sectors.last-change",
    defaultMessage: "Last change",
  },
  "mbp-models.last-sectors.prev-change": {
    id: "mbp-models.last-sectors.prev-change",
    defaultMessage: "Previous change",
  },
  "mbp-models.last-sectors.prev-sector": {
    id: "mbp-models.last-sectors.prev-sector",
    defaultMessage: "Previous sector",
  },
  "mbp-models.last-sectors.sector-editor": {
    id: "mbp-models.last-sectors.sector-editor",
    defaultMessage: "Edit sector",
  },
  "mbp-models.regular-report.offset": {
    id: "mbp-models.regular-report.offset",
    defaultMessage: "Offset",
  },
  "mbp-models.regular-report.edit": {
    id: "mbp-models.regular-report.edit",
    defaultMessage: "Edit",
  },
  "alarm-components.ok": {
    id: "alarm-components.ok",
    defaultMessage: "OK",
  },
  "alarm-components.firesignals-edit.title": {
    id: "alarm-components.firesignals-edit.title",
    defaultMessage: "Edit firesignal",
  },
  "alarm-components.firesignals-new.title": {
    id: "alarm-components.firesignals-new.title",
    defaultMessage: "New firesignal",
  },
  "alarm-components.customers-edit.title": {
    id: "alarm-components.customers-edit.title",
    defaultMessage: "Edit customer",
  },
  "alarm-components.customers-new.title": {
    id: "alarm-components.customers-new.title",
    defaultMessage: "New customer",
  },
  "alarm-components.code-tables-edit.title": {
    id: "alarm-components.code-tables-edit.title",
    defaultMessage: "Edit code table",
  },
  "alarm-components.customer-groups-edit.title": {
    id: "alarm-components.customer-groups-edit.title",
    defaultMessage: "Edit customer group",
  },
  "alarm-components.customer-groups-new.title": {
    id: "alarm-components.customer-groups-new.title",
    defaultMessage: "New customer group",
  },
  "alarm-components.sectors-edit.title": {
    id: "alarm-components.sectors-edit.title",
    defaultMessage: "Edit map sector",
  },
  "alarm-components.sectors-new.title": {
    id: "alarm-components.sectors-new.title",
    defaultMessage: "New map sector",
  },
  "alarm-components.fix-beacons-edit.title": {
    id: "alarm-components.fix-beacons-edit.title",
    defaultMessage: "Edit fix beacon",
  },
  "alarm-components.fix-beacons-new.title": {
    id: "alarm-components.fix-beacons-new.title",
    defaultMessage: "New fix beacon",
  },
  "alarm-components.fix-recievers-edit.title": {
    id: "alarm-components.fix-recievers-edit.title",
    defaultMessage: "Edit fix receiver",
  },
  "alarm-components.fix-recievers-new.title": {
    id: "alarm-components.fix-recievers-new.title",
    defaultMessage: "New fix receiver",
  },
  "alarm-components.moving-beacons-edit.title": {
    id: "alarm-components.moving-beacons-edit.title",
    defaultMessage: "Edit moving beacon",
  },
  "alarm-components.moving-beacons-new.title": {
    id: "alarm-components.moving-beacons-new.title",
    defaultMessage: "New moving beacon",
  },
  "alarm-components.moving-recievers-edit.title": {
    id: "alarm-components.moving-recievers-edit.title",
    defaultMessage: "Edit Moving receiver",
  },
  "alarm-components.moving-recievers-new.title": {
    id: "alarm-components.moving-recievers-new.title",
    defaultMessage: "New moving receiver",
  },
  "alarm-components.interventions.title": {
    id: "alarm-components.interventions.title",
    defaultMessage: "Intervention query",
  },
  "alarm-components.icons-edit.title": {
    id: "alarm-components.icons-edit.title",
    defaultMessage: "Edit icon",
  },
  "alarm-components.icons-new.title": {
    id: "alarm-components.icons-new.title",
    defaultMessage: "New icon",
  },
  "alarm-components.sounds-edit.title": {
    id: "alarm-components.sounds-edit.title",
    defaultMessage: "Edit sound",
  },
  "alarm-components.sounds-new.title": {
    id: "alarm-components.sounds-new.title",
    defaultMessage: "New sound",
  },
  "alarm-components.map-profiles-edit.title": {
    id: "alarm-components.map-profiles-edit.title",
    defaultMessage: "Edit map profile",
  },
  "alarm-components.map-profiles-new.title": {
    id: "alarm-components.map-profiles-new.title",
    defaultMessage: "New map profile",
  },
  "alarm-components.pois-edit.title": {
    id: "alarm-components.pois-edit.title",
    defaultMessage: "Edit POI",
  },
  "alarm-components.pois-new.title": {
    id: "alarm-components.pois-new.title",
    defaultMessage: "New POI",
  },
  "alarm-components.groundplans-edit.title": {
    id: "alarm-components.groundplans-edit.title",
    defaultMessage: "Edit ground plan",
  },
  "alarm-components.groundplans-new.title": {
    id: "alarm-components.groundplans-new.title",
    defaultMessage: "New ground plan",
  },
  "alarm-components.object-types-edit.title": {
    id: "alarm-components.object-types-edit.title",
    defaultMessage: "Edit object type",
  },
  "alarm-components.object-types-new.title": {
    id: "alarm-components.object-types-new.title",
    defaultMessage: "New object type",
  },
  "alarm-components.authorities-edit.title": {
    id: "alarm-components.authorities-edit.title",
    defaultMessage: "Edit authority",
  },
  "alarm-components.authorities-new.title": {
    id: "alarm-components.authorities-new.title",
    defaultMessage: "New authority",
  },
  "alarm-components.guards-list.title": {
    id: "alarm-components.guards-list.title",
    defaultMessage: "Guards",
  },
  "alarm-components.guards-edit.title": {
    id: "alarm-components.guards-edit.title",
    defaultMessage: "Edit guard",
  },
  "alarm-components.guards-new.title": {
    id: "alarm-components.guards-new.title",
    defaultMessage: "New guard",
  },
  "alarm-components.installers-edit.title": {
    id: "alarm-components.installers-edit.title",
    defaultMessage: "Edit installer",
  },
  "alarm-components.installers-new.title": {
    id: "alarm-components.installers-new.title",
    defaultMessage: "New installer",
  },
  "alarm-components.zone-types-edit.title": {
    id: "alarm-components.zone-types-edit.title",
    defaultMessage: "Edit zone type",
  },
  "alarm-components.zone-types-new.title": {
    id: "alarm-components.zone-types-new.title",
    defaultMessage: "New zone type",
  },
  "alarm-components.battery-types-edit.title": {
    id: "alarm-components.battery-types-edit.title",
    defaultMessage: "Edit battery type",
  },
  "alarm-components.battery-types-new.title": {
    id: "alarm-components.battery-types-new.title",
    defaultMessage: "New battery type",
  },
  "alarm-components.equipment-types-edit.title": {
    id: "alarm-components.equipment-types-edit.title",
    defaultMessage: "Edit equipment type",
  },
  "alarm-components.equipment-types-new.title": {
    id: "alarm-components.equipment-types-new.title",
    defaultMessage: "New equipment type",
  },
  "alarm-components.sounder-types-edit.title": {
    id: "alarm-components.sounder-types-edit.title",
    defaultMessage: "Edit sounder type",
  },
  "alarm-components.sounder-types-new.title": {
    id: "alarm-components.sounder-types-new.title",
    defaultMessage: "New sounder type",
  },
  "alarm-components.sensor-types-edit.title": {
    id: "alarm-components.sensor-types-edit.title",
    defaultMessage: "Edit sensor type",
  },
  "alarm-components.sensor-types-new.title": {
    id: "alarm-components.sensor-types-new.title",
    defaultMessage: "New sensor type",
  },
  "alarm-components.cdec-all.table": {
    id: "alarm-components.cdec-all.table",
    defaultMessage: "Show on table",
  },
  "alarm-components.cdec-chart.title": {
    id: "alarm-components.cdec-chart.title",
    defaultMessage: "Show on chart",
  },
  "alarm-components.cdec-map.title": {
    id: "alarm-components.cdec-map.title",
    defaultMessage: "Show on map",
  },
  "alarm-components.operators-edit.title": {
    id: "alarm-components.operators-edit.title",
    defaultMessage: "Edit operator",
  },
  "alarm-components.operators-new.title": {
    id: "alarm-components.operators-new.title",
    defaultMessage: "New operator",
  },
  "alarm-components.events-edit.title": {
    id: "alarm-components.events-edit.title",
    defaultMessage: "Edit event type",
  },
  "alarm-components.events-new.title": {
    id: "alarm-components.events-new.title",
    defaultMessage: "New event type",
  },
  "alarm-components.rfid-tokens-list.title": {
    id: "alarm-components.rfid-tokens-list.title",
    defaultMessage: "Patrol RFID tokens",
  },
  "alarm-components.rfid-tokens-edit.title": {
    id: "alarm-components.rfid-tokens-edit.title",
    defaultMessage: "Edit patrol RFID token",
  },
  "alarm-components.rfid-tokens-new.title": {
    id: "alarm-components.rfid-tokens-new.title",
    defaultMessage: "New patrol RFID token",
  },
  "alarm-components.rfid-time-reports-list.title": {
    id: "alarm-components.rfid-time-reports-list.title",
    defaultMessage: "RFID Timesheets",
  },
  "alarm-components.rfid-time-reports-edit.title": {
    id: "alarm-components.rfid-time-reports-edit.title",
    defaultMessage: "Edit RFID Timesheet",
  },
  "alarm-components.rfid-time-reports-new.title": {
    id: "alarm-components.rfid-time-reports-new.title",
    defaultMessage: "New RFID Timesheet",
  },
  "alarm-components.categories-edit.title": {
    id: "alarm-components.categories-edit.title",
    defaultMessage: "Edit event category",
  },
  "alarm-components.categories-new.title": {
    id: "alarm-components.categories-new.title",
    defaultMessage: "New event category",
  },
  "alarm-components.acs-users-edit.title": {
    id: "alarm-components.acs-users-edit.title",
    defaultMessage: "Edit RFID card",
  },
  "alarm-components.acs-users-new.title": {
    id: "alarm-components.acs-users-new.title",
    defaultMessage: "New RFID card",
  },
  "alarm-components.acs-groups-edit.title": {
    id: "alarm-components.acs-groups-edit.title",
    defaultMessage: "Edit RFID group",
  },
  "mbp-alarm-intervention.intervention-worksheet": {
    id: "mbp-alarm-intervention.intervention-worksheet",
    defaultMessage: "Intervention",
  },
  "mbp-alarm-intervention.print": {
    id: "mbp-alarm-intervention.print",
    defaultMessage: "Print",
  },
  "mbp-alarm-intervention.set-owner": {
    id: "mbp-alarm-intervention.set-owner",
    defaultMessage: "Set assignee",
  },
  "mbp-alarm-intervention.reset-owner": {
    id: "mbp-alarm-intervention.reset-owner",
    defaultMessage: "Reset assignee",
  },
  "mbp-alarm-intervention.close": {
    id: "mbp-alarm-intervention.close",
    defaultMessage: "Close intervention",
  },
  "mbp-alarm-intervention.log": {
    id: "mbp-alarm-intervention.log",
    defaultMessage: "Log",
  },
  "mbp-alarm-intervention.new-entry": {
    id: "mbp-alarm-intervention.new-entry",
    defaultMessage: "New log entry",
  },
  "mbp-alarm-intervention.customer": {
    id: "mbp-alarm-intervention.customer",
    defaultMessage: "Customer",
  },
  "mbp-alarm-intervention.customer-details": {
    id: "mbp-alarm-intervention.customer-details",
    defaultMessage: "Customer details",
  },
  "mbp-alarm-intervention.customer-remarks": {
    id: "mbp-alarm-intervention.customer-remarks",
    defaultMessage: "Customer remarks",
  },
  "mbp-alarm-intervention.customer-zones": {
    id: "mbp-alarm-intervention.customer-zones",
    defaultMessage: "Customer zones",
  },
  "mbp-alarm-intervention.customer-partitions": {
    id: "mbp-alarm-intervention.customer-partitions",
    defaultMessage: "Customer partitions",
  },
  "mbp-alarm-intervention.customer-notifiables": {
    id: "mbp-alarm-intervention.customer-notifiables",
    defaultMessage: "Contacts to be notified",
  },
  "mbp-alarm-intervention.customer-interval-rules": {
    id: "mbp-alarm-intervention.customer-interval-rules",
    defaultMessage: "Customer interval rules",
  },
  "mbp-alarm-intervention.zone-type": {
    id: "mbp-alarm-intervention.zone-type",
    defaultMessage: "Zone type",
  },
  "mbp-alarm-intervention.sensor-type": {
    id: "mbp-alarm-intervention.sensor-type",
    defaultMessage: "Sensor type",
  },
  "mbp-alarm-intervention.temporary-inactive": {
    id: "mbp-alarm-intervention.temporary-inactive",
    defaultMessage: "Temporary inactive",
  },
  "mbp-alarm-intervention.open-in-observer": {
    id: "mbp-alarm-intervention.open-in-observer",
    defaultMessage: "Position data in Observer",
  },
  "mbp-alarm-intervention.sms-object": {
    id: "mbp-alarm-intervention.sms-object",
    defaultMessage: "object",
  },
  "mbp-alarm-intervention.position-data": {
    id: "mbp-alarm-intervention.position-data",
    defaultMessage: "Position data",
  },
  "alarm.customer-interval-rules.interval-rules": {
    id: "alarm.customer-interval-rules.interval-rules",
    defaultMessage: "Interval rules",
  },
  "alarm.customer-interval-rules.edit-interval-rule": {
    id: "alarm.customer-interval-rules.edit-interval-rule",
    defaultMessage: "Edit interval rule",
  },
  "alarm.customer-interval-rules.new-interval-rule": {
    id: "alarm.customer-interval-rules.new-interval-rule",
    defaultMessage: "New interval rule",
  },
  "alarm.customer-interval-rules.start-time": {
    id: "alarm.customer-interval-rules.start-time",
    defaultMessage: "Beginning",
  },
  "alarm.customer-interval-rules.interval-length": {
    id: "alarm.customer-interval-rules.interval-length",
    defaultMessage: "Length",
  },
  "alarm.customer-interval-rules.zone-user-rfid-token": {
    id: "alarm.customer-interval-rules.zone-user-rfid-token",
    defaultMessage: "Zone/User/RFID token",
  },
  "alarm.customer-interval-rules.expectation-type": {
    id: "alarm.customer-interval-rules.expectation-type",
    defaultMessage: "Expectation",
  },
  "alarm.customer-interval-rules.repetation-count": {
    id: "alarm.customer-interval-rules.repetation-count",
    defaultMessage: "Repetitions",
  },
  "alarm.customer-interval-rules.repetation-interval": {
    id: "alarm.customer-interval-rules.repetation-interval",
    defaultMessage: "Repetition delay",
  },
  "alarm.customer-interval-rules.expect-event": {
    id: "alarm.customer-interval-rules.expect-event",
    defaultMessage: "Expect event",
  },
  "alarm.customer-interval-rules.not-expect-event": {
    id: "alarm.customer-interval-rules.not-expect-event",
    defaultMessage: "Expect no event",
  },
  "alarm.customer-interval-rules.expect-armed": {
    id: "alarm.customer-interval-rules.expect-armed",
    defaultMessage: "Expect armed",
  },
  "alarm.customer-interval-rules.expect-not-armed": {
    id: "alarm.customer-interval-rules.expect-not-armed",
    defaultMessage: "Expect not armed",
  },
  "mbp-alarm-intervention.subtask.sms": {
    id: "mbp-alarm-intervention.subtask.sms",
    defaultMessage: "SMS",
  },
  "mbp-alarm-intervention.subtask.event-list": {
    id: "mbp-alarm-intervention.subtask.event-list",
    defaultMessage: "Event list",
  },
  "mbp-alarm-menu.customer-service": {
    id: "mbp-alarm-menu.customer-service",
    defaultMessage: "Customer service",
  },
  "mbp-alarm-menu.rfid-tokens": {
    id: "mbp-alarm-menu.rfid-tokens",
    defaultMessage: "RFID tokens",
  },
  "mbp-alarm-menu.intervention-query": {
    id: "mbp-alarm-menu.intervention-query",
    defaultMessage: "Intervention query",
  },
  "mbp-alarm-menu.patrols": {
    id: "mbp-alarm-menu.patrols",
    defaultMessage: "Patrols",
  },
  "mbp-alarm-menu.indoor-positioning": {
    id: "mbp-alarm-menu.indoor-positioning",
    defaultMessage: "Indoor positioning",
  },
  "mbp-alarm-menu.logout": {
    id: "mbp-alarm-menu.logout",
    defaultMessage: "Logout",
  },
  "alarm.map-tracking.sectors": {
    id: "alarm.map-tracking.sectors",
    defaultMessage: "Sectors",
  },
  "alarm.map-tracking.empty": {
    id: "alarm.map-tracking.empty",
    defaultMessage: "No results",
  },
  "alarm.map-tracking.sensor-zones": {
    id: "alarm.map-tracking.sensor-zones",
    defaultMessage: "Sensor zones",
  },
  "mbp-login.invalid-username-password": {
    id: "mbp-login.invalid-username-password",
    defaultMessage: "Invalid username and/or password.",
  },
  "mbp-login.login": {
    id: "mbp-login.login",
    defaultMessage: "Login",
  },
  "mbp-login.language": {
    id: "mbp-login.language",
    defaultMessage: "Language",
  },
  "alarm-code-table.import-dialog-title": {
    id: "alarm-code-table.import-dialog-title",
    defaultMessage: "Import code table from .json file",
  },
  "alarm-code-table.import-dialog-description": {
    id: "alarm-code-table.import-dialog-description",
    defaultMessage: "The file must be in JSON format",
  },
  "alarm.firesignal.active": {
    id: "alarm.firesignal.active",
    defaultMessage: "TFK forwarding is active",
  },
  "alarm.firesignal.cancel": {
    id: "alarm.firesignal.cancel",
    defaultMessage: "Cancel",
  },
  "alarm.firesignal.check-firesignal": {
    id: "alarm.firesignal.check-firesignal",
    defaultMessage: "Check firesignal",
  },
  "alarm.firesignal.customer": {
    id: "alarm.firesignal.customer",
    defaultMessage: "Customer",
  },
  "alarm.firesignal.id": {
    id: "alarm.firesignal.id",
    defaultMessage: "Fireserver identifier",
  },
  "alarm.firesignal.import-data": {
    id: "alarm.firesignal.import-data",
    defaultMessage: "Import data",
  },
  "alarm.firesignal.okay": {
    id: "alarm.firesignal.okay",
    defaultMessage: "O.K.",
  },
  "alarm.firesignal.upload-firesignal": {
    id: "alarm.firesignal.upload-firesignal",
    defaultMessage: "Upload firesignal",
  },
  "alarm.firesignal.unitids": {
    id: "alarm.firesignal.unitids",
    defaultMessage: "Helios IDs",
  },
  "alarm.firesignal.remove-firesignal": {
    id: "alarm.firesignal.remove-firesignal",
    defaultMessage: "Delete firesignal",
  },
  "alarm.firesignal.apikey": {
    id: "alarm.firesignal.apikey",
    defaultMessage: "TFK API key *",
  },
  "alarm.firesignal.is-developer-key": {
    id: "alarm.firesignal.is-developer-key",
    defaultMessage: "Developer API key",
  },
  "alarm.firesignal.contactname": {
    id: "alarm.firesignal.contactname",
    defaultMessage: "Client's name *",
  },
  "alarm.firesignal.city": {
    id: "alarm.firesignal.city",
    defaultMessage: "City *",
  },
  "alarm.firesignal.address": {
    id: "alarm.firesignal.address",
    defaultMessage: "House number and street name *",
  },
  "alarm.firesignal.zipcode": {
    id: "alarm.firesignal.zipcode",
    defaultMessage: "ZIP code *",
  },
  "alarm.firesignal.phone1": {
    id: "alarm.firesignal.phone1",
    defaultMessage: "Phone number (or mobile) *",
  },
  "alarm.firesignal.coords": {
    id: "alarm.firesignal.coords",
    defaultMessage: "Coordinates in WGS84 decimal format *",
  },
  "alarm.firesignal.latitude": {
    id: "alarm.firesignal.latitude",
    defaultMessage: "Latitude *",
  },
  "alarm.firesignal.longitude": {
    id: "alarm.firesignal.longitude",
    defaultMessage: "Longitude *",
  },
  "alarm.firesignal.phone2": {
    id: "alarm.firesignal.phone2",
    defaultMessage: "Second phone number",
  },
  "alarm.firesignal.phone3": {
    id: "alarm.firesignal.phone3",
    defaultMessage: "Third phone number",
  },
  "alarm.firesignal.phone4": {
    id: "alarm.firesignal.phone4",
    defaultMessage: "Fourth phone number",
  },
  "alarm.firesignal.customer-type": {
    id: "alarm.firesignal.customer-type",
    defaultMessage: "Customer type",
  },
  "alarm.firesignal.cancelcode": {
    id: "alarm.firesignal.cancelcode",
    defaultMessage: "Alert cancel password *",
  },
  "alarm.firesignal.cancelname": {
    id: "alarm.firesignal.cancelname",
    defaultMessage: "Full name of the person authorized to cancel the alert *",
  },
  "alarm.firesignal.cancelphone": {
    id: "alarm.firesignal.cancelphone",
    defaultMessage:
      "Phone number of the person authorized to cancel the alert *",
  },
  "alarm.firesignal.safekey": {
    id: "alarm.firesignal.safekey",
    defaultMessage: "Safe key access informations",
  },
  "alarm.firesignal.comment": {
    id: "alarm.firesignal.comment",
    defaultMessage: "Comments on the client and the object",
  },
  "alarm.firesignal.new-unitid": {
    id: "alarm.firesignal.new-unitid",
    defaultMessage: "New Helios ID",
  },
  "alarm.firesignal.unitid-error": {
    id: "alarm.firesignal.unitid-error",
    defaultMessage: "Decimal number between 1 and 5 digits",
  },
  "alarm.firesignal.customers": {
    id: "alarm.firesignal.customers",
    defaultMessage: "Related devices",
  },
  "alarm.firesignal.error-transmission": {
    id: "alarm.firesignal.error-transmission",
    defaultMessage: "Other error signals",
  },
  "alarm.firesignal.uniterrordata.settings": {
    id: "alarm.firesignal.uniterrordata.settings",
    defaultMessage: "Forward errors",
  },
  "alarm.firesignal.uniterrordata.onlyalerts": {
    id: "alarm.firesignal.uniterrordata.onlyalerts",
    defaultMessage: "Forward only fire alerts",
  },
  "alarm.firesignal.uniterrordata.checklifesigns": {
    id: "alarm.firesignal.uniterrordata.checklifesigns",
    defaultMessage: "Check lifesign on the MOHAnet Fireserver",
  },
  "alarm.firesignal.notifiables": {
    id: "alarm.firesignal.notifiables",
    defaultMessage: "List of notifiable persons in case of fire",
  },
  "alarm.firesignal.tfk-log": {
    id: "alarm.firesignal.tfk-log",
    defaultMessage: "TFK log",
  },
  "alarm.firesignal.table-header.sent": {
    id: "alarm.firesignal.table-header.sent",
    defaultMessage: "Sent",
  },
  "alarm.firesignal.table-header.received": {
    id: "alarm.firesignal.table-header.received",
    defaultMessage: "Received",
  },
  "alarm.firesignal.customer-types.private": {
    id: "alarm.firesignal.customer-types.private",
    defaultMessage: "private",
  },
  "alarm.firesignal.customer-types.company": {
    id: "alarm.firesignal.customer-types.company",
    defaultMessage: "corporate",
  },
  "alarm.firesignal.customer-types.government": {
    id: "alarm.firesignal.customer-types.government",
    defaultMessage: "government",
  },
  "alarm.firesignal.confirm-text": {
    id: "alarm.firesignal.confirm-text",
    defaultMessage:
      "If you delete a firesignal from the TFK server, then it can only be registered under a different ID. For temporary inactive status use the 'Active' checkbox. Are you sure you want to delete this firesignal?",
  },
  "alarm.firesignal.confirm-delete": {
    id: "alarm.firesignal.confirm-delete",
    defaultMessage: "Delete the firesignal permanently",
  },
  "alarm.firesignal.check-unitids": {
    id: "alarm.firesignal.check-unitids",
    defaultMessage: "Check Helios IDs",
  },
  "alarm.firesignal.check-codetable": {
    id: "alarm.firesignal.check-codetable",
    defaultMessage: "Check codetable",
  },
  "alarm.firesignal.check-client": {
    id: "alarm.firesignal.check-client",
    defaultMessage: "Check fireserver",
  },
  "alarm.firesignal.response-message": {
    id: "alarm.firesignal.response-message",
    defaultMessage: "Message",
  },
  "alarm.firesignal.response-timestamp": {
    id: "alarm.firesignal.response-timestamp",
    defaultMessage: "Timestamp",
  },
  "alarm.firesignal.messageapikey": {
    id: "alarm.firesignal.messageapikey",
    defaultMessage: "SMS API key",
  },
  "alarm.firesignal.phones": {
    id: "alarm.firesignal.phones",
    defaultMessage: "Phone numbers",
  },
  "alarm.firesignal.new-phone": {
    id: "alarm.firesignal.new-phone",
    defaultMessage: "New phone number",
  },
  "alarm.firesignal.message": {
    id: "alarm.firesignal.message",
    defaultMessage: "SMS notification",
  },
  "alarm.firesignal.tfk": {
    id: "alarm.firesignal.tfk",
    defaultMessage: "TFK data",
  },
  "alarm.rfid-manager.rfid-import.title": {
    id: "alarm.rfid-manager.rfid-import.title",
    defaultMessage: "Import RFIDs from csv file",
  },
  "alarm.rfid-manager.rfid-import.description": {
    id: "alarm.rfid-manager.rfid-import.description",
    defaultMessage:
      "The file has to be in the format specified below (RFID, Name, External ID):",
  },
  "alarm.rfid-manager.rfid-import.example": {
    id: "alarm.rfid-manager.rfid-import.example",
    defaultMessage:
      '"001110"; "Joe Shmoe"; ""\n"123456"; "Guest card"; "553354"\n"0"; "Some Important Key"; "999"',
  },
  "alarm.rfid-manager.edit-rfid": {
    id: "alarm.rfid-manager.edit-rfid",
    defaultMessage: "Edit RFID",
  },
  "alarm.rfid-manager.remove-rfid": {
    id: "alarm.rfid-manager.remove-rfid",
    defaultMessage: "Delete selected RFIDs",
  },
  "alarm.rfid-manager.add-to-group": {
    id: "alarm.rfid-manager.add-to-group",
    defaultMessage: "Add to group",
  },
  "alarm.rfid-manager.rfid-groups": {
    id: "alarm.rfid-manager.rfid-groups",
    defaultMessage: "RFID groups",
  },
  "alarm.rfid-manager.export-group": {
    id: "alarm.rfid-manager.export-group",
    defaultMessage: "Export group",
  },
  "alarm.rfid-manager.import-to-group": {
    id: "alarm.rfid-manager.import-to-group",
    defaultMessage: "Import into group",
  },
  "alarm.rfid-manager.add-group": {
    id: "alarm.rfid-manager.add-group",
    defaultMessage: "Add group",
  },
  "alarm.rfid-manager.edit-group": {
    id: "alarm.rfid-manager.edit-group",
    defaultMessage: "Edit group",
  },
  "alarm.rfid-manager.remove-group": {
    id: "alarm.rfid-manager.remove-group",
    defaultMessage: "Delete group",
  },
  "alarm.rfid-manager.send-to-unit": {
    id: "alarm.rfid-manager.send-to-unit",
    defaultMessage: "Send to the device",
  },
  "alarm.rfid-manager.export.name": {
    id: "alarm.rfid-manager.export.name",
    defaultMessage: "NAME",
  },
  "alarm.rfid-manager.export.trzs": {
    id: "alarm.rfid-manager.export.trzs",
    defaultMessage: "TRZS",
  },
  "alarm.rfid-manager.table-header.export": {
    id: "alarm.rfid-manager.table-header.export",
    defaultMessage: "Export",
  },
  "alarm.rfid-manager.table-header.toggle-all": {
    id: "alarm.rfid-manager.table-header.toggle-all",
    defaultMessage: "Toggle all",
  },
  "alarm.rfid-manager.in-progress": {
    id: "alarm.rfid-manager.in-progress",
    defaultMessage: "In progress...",
  },
  "alarm.rfid-manager.response-success": {
    id: "alarm.rfid-manager.response-success",
    defaultMessage:
      "{count} unique {count, plural, one {RFID has} other {RFIDs have}} been sent successfully. Server response: {res}",
  },
  "alarm.rfid-manager.response-error": {
    id: "alarm.rfid-manager.response-error",
    defaultMessage: "Delivery failed.",
  },
  "alarm-acs-group.parent-group": {
    id: "alarm-acs-group.parent-group",
    defaultMessage: "Parent group",
  },
  "alarm-status-overview.last-event": {
    id: "alarm-status-overview.last-event",
    defaultMessage: "Last event",
  },
  "alarm.customer-filter.helios": {
    id: "alarm.customer-filter.helios",
    defaultMessage: "Helios",
  },
  "alarm.customer-filter.no-group": {
    id: "alarm.customer-filter.no-group",
    defaultMessage: "No group",
  },
  "alarm.customer.clone-customer": {
    id: "alarm.customer.clone-customer",
    defaultMessage: "Clone customer",
  },
  "alarm.customer.new-identifier": {
    id: "alarm.customer.new-identifier",
    defaultMessage: "New identifier",
  },
  "alarm.customer.new-identifier-input-error": {
    id: "alarm.customer.new-identifier-input-error",
    defaultMessage: "Unique four digit hexadecimal number, e.g. 001F.",
  },
  "alarm.customer.dialog.attention": {
    id: "alarm.customer.dialog.attention",
    defaultMessage: "Attention!",
  },
  "alarm.customer.dialog.confirm-delete-msg": {
    id: "alarm.customer.dialog.confirm-delete-msg",
    defaultMessage: "Are you sure you want to delete the customer?",
  },
  "alarm.customer.dialog.confirm-delete": {
    id: "alarm.customer.dialog.confirm-delete",
    defaultMessage: "Confirm deletion",
  },
  "alarm.customer.dialog.confirm-delete-instructions": {
    id: "alarm.customer.dialog.confirm-delete-instructions",
    defaultMessage: "Type in the 4 digit identifier of the customer!",
  },
  "alarm.customer.basic-info": {
    id: "alarm.customer.basic-info",
    defaultMessage: "Basic informations",
  },
  "alarm.customer.events-of-last-day": {
    id: "alarm.customer.events-of-last-day",
    defaultMessage: "Events from the last 24 hours",
  },
  "alarm.customer.events-of-last-week": {
    id: "alarm.customer.events-of-last-week",
    defaultMessage: "Events from the last 7 days",
  },
  "alarm.customer.events-of-last-30-days": {
    id: "alarm.customer.events-of-last-30-days",
    defaultMessage: "Events from the last 30 days",
  },
  "alarm.customer.delete-customer": {
    id: "alarm.customer.delete-customer",
    defaultMessage: "Delete customer",
  },
  "alarm.customer.object-type": {
    id: "alarm.customer.object-type",
    defaultMessage: "Object type",
  },
  "alarm.customer.api-key": {
    id: "alarm.customer.api-key",
    defaultMessage: "API key",
  },
  "alarm.customer.toolbox-link": {
    id: "alarm.customer.toolbox-link",
    defaultMessage: "Configure the device ({unitid}) in Helios Toolboxban",
  },
  "alarm.customer.firesignal-link": {
    id: "alarm.customer.firesignal-link",
    defaultMessage:
      "Jump to the firesignal connected to the device ({account})",
  },
  "alarm.customer.firesignal-check": {
    id: "alarm.customer.firesignal-check",
    defaultMessage: "Firesignal check",
  },
  "alarm.customer.tabs.lifesigns-and-regular-report": {
    id: "alarm.customer.tabs.lifesigns-and-regular-report",
    defaultMessage: "Lifesigns and regular reports",
  },
  "alarm.customer.tabs.technical-informations": {
    id: "alarm.customer.tabs.technical-informations",
    defaultMessage: "Technical informations",
  },
  "alarm.customer.tabs.map-and-locations": {
    id: "alarm.customer.tabs.map-and-locations",
    defaultMessage: "Map and position",
  },
  "alarm.customer.tabs.partitions-zones-and-storno": {
    id: "alarm.customer.tabs.partitions-zones-and-storno",
    defaultMessage: "Partitions, zones and event storno",
  },
  "alarm.customer.tabs.users-and-rfid-tokens": {
    id: "alarm.customer.tabs.users-and-rfid-tokens",
    defaultMessage: "Users and rfid tokens",
  },
  "alarm.customer.tabs.interval-rules": {
    id: "alarm.customer.tabs.interval-rules",
    defaultMessage: "Inteval rules",
  },
  "alarm.customer.tabs.notifiables-and-tasks": {
    id: "alarm.customer.tabs.notifiables-and-tasks",
    defaultMessage: "Notifiables and tasks",
  },
  "alarm.customer.tabs.event-rules": {
    id: "alarm.customer.tabs.event-rules",
    defaultMessage: "Event rules",
  },
  "alarm.customer.lifesigns": {
    id: "alarm.customer.lifesigns",
    defaultMessage: "Lifesigns",
  },
  "alarm.customer.lifesign-check": {
    id: "alarm.customer.lifesign-check",
    defaultMessage: "Lifesign check",
  },
  "alarm.customer.gprs-lifesign-check": {
    id: "alarm.customer.gprs-lifesign-check",
    defaultMessage: "GPRS lifesign check",
  },
  "alarm.customer.complex-lifesign-check": {
    id: "alarm.customer.complex-lifesign-check",
    defaultMessage: "Complex lifesign check",
  },
  "alarm.customer.hide-lifesign": {
    id: "alarm.customer.hide-lifesign",
    defaultMessage: "Hide lifesign events",
  },
  "alarm.customer.hide-gprs-lifesign": {
    id: "alarm.customer.hide-gprs-lifesign",
    defaultMessage: "Hide GPRS lifesign events",
  },
  "alarm.customer.regular-reports": {
    id: "alarm.customer.regular-reports",
    defaultMessage: "Regular reports",
  },
  "alarm.customer.open-close-list": {
    id: "alarm.customer.open-close-list",
    defaultMessage: "Open / close list",
  },
  "alarm.customer.full-list": {
    id: "alarm.customer.full-list",
    defaultMessage: "Full list",
  },
  "alarm.customer.installer-info": {
    id: "alarm.customer.installer-info",
    defaultMessage: "Installer informations",
  },
  "alarm.customer.equipment-info": {
    id: "alarm.customer.equipment-info",
    defaultMessage: "Equipment informations",
  },
  "alarm.customer.helios-id": {
    id: "alarm.customer.helios-id",
    defaultMessage: "Helios identifier",
  },
  "alarm.customer.equipment-type": {
    id: "alarm.customer.equipment-type",
    defaultMessage: "Equipment type",
  },
  "alarm.customer.equipment-serial": {
    id: "alarm.customer.equipment-serial",
    defaultMessage: "Serial number",
  },
  "alarm.customer.equipment-partitioned": {
    id: "alarm.customer.equipment-partitioned",
    defaultMessage: "Partitioned",
  },
  "alarm.customer.battery-type": {
    id: "alarm.customer.battery-type",
    defaultMessage: "Battery type",
  },
  "alarm.customer.sounder-type": {
    id: "alarm.customer.sounder-type",
    defaultMessage: "Sounder type",
  },
  "alarm.customer.legacy-title": {
    id: "alarm.customer.legacy-title",
    defaultMessage: "Legacy account translation",
  },
  "alarm.customer.enabled": {
    id: "alarm.customer.enabled",
    defaultMessage: "Enabled",
  },
  "alarm.customer.map-profile": {
    id: "alarm.customer.map-profile",
    defaultMessage: "Mapprofile",
  },
  "alarm.customer.object-radius": {
    id: "alarm.customer.object-radius",
    defaultMessage: "Object radius",
  },
  "alarm.customer.allowed-radius": {
    id: "alarm.customer.allowed-radius",
    defaultMessage: "Allowed distance",
  },
  "alarm.customer.current-location": {
    id: "alarm.customer.current-location",
    defaultMessage: "Current location",
  },
  "alarm.customer.coordinates": {
    id: "alarm.customer.coordinates",
    defaultMessage: "Coordiantes",
  },
  "alarm.customer.last-updated": {
    id: "alarm.customer.last-updated",
    defaultMessage: "Last updated",
  },
  "alarm.customer.copy-from-customer": {
    id: "alarm.customer.copy-from-customer",
    defaultMessage: "Copy from other customer",
  },
  "alarm.customer.contract-app-types.security": {
    id: "alarm.customer.contract-app-types.security",
    defaultMessage: "Security",
  },
  "alarm.customer.contract-app-types.vehicle-tracking": {
    id: "alarm.customer.contract-app-types.vehicle-tracking",
    defaultMessage: "Vehicle tracking",
  },
  "alarm.customer.contract-app-types.healthcare": {
    id: "alarm.customer.contract-app-types.healthcare",
    defaultMessage: "Healthcare",
  },
  "alarm.customer.contract-app-types.person-and-tool-tracking": {
    id: "alarm.customer.contract-app-types.person-and-tool-tracking",
    defaultMessage: "Person and tool tracking",
  },
  "alarm.customer.contract-app-types.elevator": {
    id: "alarm.customer.contract-app-types.elevator",
    defaultMessage: "Elevator",
  },
  "alarm.customer.contract-app-types.fire-and-gas-signal": {
    id: "alarm.customer.contract-app-types.fire-and-gas-signal",
    defaultMessage: "Fire and gas detection",
  },
  "alarm.customer.contract-app-types.pilot": {
    id: "alarm.customer.contract-app-types.pilot",
    defaultMessage: "Pilot",
  },
  "alarm.customer.contract-app-types.private-test": {
    id: "alarm.customer.contract-app-types.private-test",
    defaultMessage: "Private test",
  },
  "alarm.customer.contract-service-types.self-care": {
    id: "alarm.customer.contract-service-types.self-care",
    defaultMessage: "Self-care",
  },
  "alarm.customer.contract-service-types.with-notification": {
    id: "alarm.customer.contract-service-types.with-notification",
    defaultMessage: "With notification",
  },
  "alarm.customer.contract-service-types.with-patrol": {
    id: "alarm.customer.contract-service-types.with-patrol",
    defaultMessage: "With patrol / delivery",
  },
  "alarm.customer.clone-unsuccessful": {
    id: "alarm.customer.clone-unsuccessful",
    defaultMessage: "Clone unsuccessful",
  },
  "alarm.customer.delete-unsuccessful": {
    id: "alarm.customer.delete-unsuccessful",
    defaultMessage: "Delete unsuccessful",
  },
  "alarm.customer.remarks": {
    id: "alarm.customer.remarks",
    defaultMessage: "Comments",
  },
  "alarm.customer.current-sector": {
    id: "alarm.customer.current-sector",
    defaultMessage: "Current sector",
  },
  "alarm.customer.unknown-position": {
    id: "alarm.customer.unknown-position",
    defaultMessage: "Unknown position",
  },
  "alarm.customer.unknown-sector": {
    id: "alarm.customer.unknown-sector",
    defaultMessage: "Unknown sector",
  },
  "alarm.customer.regular-report": {
    id: "alarm.customer.regular-report",
    defaultMessage: "Default query",
  },
  "alarm.customer.regular-report-offset": {
    id: "alarm.customer.regular-report-offset",
    defaultMessage: "Interval offset",
  },
  "alarm.customer.regular-report-categories": {
    id: "alarm.customer.regular-report-categories",
    defaultMessage: "Filter categories",
  },
  "alarm.customer.regular-report-event-codes": {
    id: "alarm.customer.regular-report-event-codes",
    defaultMessage: "Filter event codes",
  },
  "alarm.customer.regular-report-description": {
    id: "alarm.customer.regular-report-description",
    defaultMessage: "Name of the query",
  },
  "alarm.customer.regular-report-test": {
    id: "alarm.customer.regular-report-test",
    defaultMessage: "Test query",
  },
  "alarm-customer-group.number-of-customers": {
    id: "alarm-customer-group.number-of-customers",
    defaultMessage: "Number of customers",
  },
  "alarm.map-layer.south-west": {
    id: "alarm.map-layer.south-west",
    defaultMessage: "SW",
  },
  "alarm.map-layer.north-east": {
    id: "alarm.map-layer.north-east",
    defaultMessage: "NE",
  },
  "alarm.sector.groundplan": {
    id: "alarm.sector.groundplan",
    defaultMessage: "Groundplan",
  },
  "alarm.sector.object-id": {
    id: "alarm.sector.object-id",
    defaultMessage: "Object identifier",
  },
  "alarm.moving-beacon.accelero-sabotage-interval": {
    id: "alarm.moving-beacon.accelero-sabotage-interval",
    defaultMessage: "Sabotage interval",
  },
  "alarm.moving-beacon.accelero-state": {
    id: "alarm.moving-beacon.accelero-state",
    defaultMessage: "Accelero state",
  },
  "alarm.moving-beacon.last-accelero-state-change": {
    id: "alarm.moving-beacon.last-accelero-state-change",
    defaultMessage: "Last accelero state change",
  },
  "alarm.moving-beacon.algorithm": {
    id: "alarm.moving-beacon.algorithm",
    defaultMessage: "Algorithm",
  },
  "alarm.moving-receiver.algorithm": {
    id: "alarm.moving-receiver.algorithm",
    defaultMessage: "Algorithm",
  },
  "alarm.intervention-filter.log-entries": {
    id: "alarm.intervention-filter.log-entries",
    defaultMessage: "Log entry",
  },
  "alarm.intervention-filter.log-entrie.description": {
    id: "alarm.intervention-filter.log-entrie.description",
    defaultMessage: "Multiple search terms should be separated by comma (,).",
  },
  "alarm.intervention-filter.storno": {
    id: "alarm.intervention-filter.storno",
    defaultMessage: "Storno",
  },
  "alarm.intervention-filter.closed": {
    id: "alarm.intervention-filter.closed",
    defaultMessage: "Closed",
  },
  "alarm.icon.import.description": {
    id: "alarm.icon.import.description",
    defaultMessage: "Upload icon in png or svg format",
  },
  "alarm.icon.import.size": {
    id: "alarm.icon.import.size",
    defaultMessage: "Size: {width}px x {height}px",
  },
  "mbp-admin-user.change-toolbox-user-dialog-title": {
    id: "mbp-admin-user.change-toolbox-user-dialog-title",
    defaultMessage: "Change associated Helios Toolbox user",
  },
  "mbp-admin-user.new-password": {
    id: "mbp-admin-user.new-password",
    defaultMessage: "New password",
  },
  "mbp-admin-user.toolbox-username": {
    id: "mbp-admin-user.toolbox-username",
    defaultMessage: "Helios Toolbox username",
  },
  "mbp-admin-user.toolbox-password": {
    id: "mbp-admin-user.toolbox-password",
    defaultMessage: "Helios Toolbox password",
  },
  "mbp-admin-user.change": {
    id: "mbp-admin-user.change",
    defaultMessage: "Change",
  },
  "mbp-alarm-operator.manual-event": {
    id: "mbp-alarm-operator.manual-event",
    defaultMessage: "Manual event registration",
  },
  "mbp-alarm-operator.customer-identifier": {
    id: "mbp-alarm-operator.customer-identifier",
    defaultMessage: "Customer identifier",
  },
  "mbp-alarm-operator.event-message": {
    id: "mbp-alarm-operator.event-message",
    defaultMessage: "Event message",
  },
  "mbp-alarm-operator.manual-event-message-error": {
    id: "mbp-alarm-operator.manual-event-message-error",
    defaultMessage: "Custom message, required field.",
  },
  "mbp-alarm-operator.send": {
    id: "mbp-alarm-operator.send",
    defaultMessage: "Send",
  },
  "mbp-alarm-operator.mute-all": {
    id: "mbp-alarm-operator.mute-all",
    defaultMessage: "Mute all alerts",
  },
  "mbp-alarm-operator.mute-other": {
    id: "mbp-alarm-operator.mute-other",
    defaultMessage: "Mute other sounds",
  },
  "operator-home-page.event-filters": {
    id: "operator-home-page.event-filters",
    defaultMessage: "Event filters",
  },
  "operator-home-page.hide-inactive": {
    id: "operator-home-page.hide-inactive",
    defaultMessage: "Hide inactive events",
  },
  "operator-home-page.hide-unknown-customer": {
    id: "operator-home-page.hide-unknown-customer",
    defaultMessage: "Hide unknown customers",
  },
  "operator-home-page.hide-unknown-event": {
    id: "operator-home-page.hide-unknown-event",
    defaultMessage: "Hide unknown events",
  },
  "operator-home-page.filter-incoming-events": {
    id: "operator-home-page.filter-incoming-events",
    defaultMessage: "Filter incoming events",
  },
  "operator-home-page.events-needing-intervention": {
    id: "operator-home-page.events-needing-intervention",
    defaultMessage: "Events needing intervention",
  },
  "operator-home-page.open-interventions": {
    id: "operator-home-page.open-interventions",
    defaultMessage: "Open interventions",
  },
  "operator-home-page.prevent-accidental-closing": {
    id: "operator-home-page.prevent-accidental-closing",
    defaultMessage: "Prevent closing accidentally",
  },
  "me-table.cell.play": {
    id: "me-table.cell.play",
    defaultMessage: "Play",
  },
  "mbp-list.remove-question": {
    id: "mbp-list.remove-question",
    defaultMessage: "Are you sure you want to delete this item?",
  },
  "mbp-admin-page.discard-question": {
    id: "mbp-admin-page.discard-question",
    defaultMessage: "You have unsaved changes. Do you want to discard them?",
  },
  "mbp-admin-page.discard": {
    id: "mbp-admin-page.discard",
    defaultMessage: "Discard",
  },
  "alarm.status-overview-filter.armed-true": {
    id: "alarm.status-overview-filter.armed-true",
    defaultMessage: "Armed",
  },
  "alarm.status-overview-filter.armed-false": {
    id: "alarm.status-overview-filter.armed-false",
    defaultMessage: "Disarmed",
  },
  "alarm.cdec-filter.unknown-object-type": {
    id: "alarm.cdec-filter.unknown-object-type",
    defaultMessage: "Unknown object type",
  },
  "alarm.cdec-filter.unknown-event-type": {
    id: "alarm.cdec-filter.unknown-event-type",
    defaultMessage: "Unknown event",
  },
  "alarm.cdec-filter.unknown-equipment-type": {
    id: "alarm.cdec-filter.unknown-equipment-type",
    defaultMessage: "Unknown equipment type",
  },
  "alarm.cdec-filter.unknown-installer": {
    id: "alarm.cdec-filter.unknown-installer",
    defaultMessage: "Unknown installer",
  },
  "alarm.cdec-filter.event-code-description": {
    id: "alarm.cdec-filter.event-code-description",
    defaultMessage: "Multiple event codes should be separated by comma (,).",
  },
  "alarm.cdec-filter.in-regular-report": {
    id: "alarm.cdec-filter.in-regular-report",
    defaultMessage: "In regular report",
  },
  "alarm.cdec-filter.contract-number-description": {
    id: "alarm.cdec-filter.contract-number-description",
    defaultMessage: "The customer's contract number.",
  },
  "alarm.cdec-filter.billing-id-description": {
    id: "alarm.cdec-filter.billing-id-description",
    defaultMessage: "The customer's billing ID.",
  },
  "alarm.sound.descr": {
    id: "alarm.sound.descr",
    defaultMessage: "System sound",
  },
  "alarm.sound.import-title": {
    id: "alarm.sound.import-title",
    defaultMessage: "Upload .waw audio file",
  },
  "alarm.sound.import-description": {
    id: "alarm.sound.import-description",
    defaultMessage: "The audio file has to be in .waw format",
  },
  "alarm.map-profile.alarm-icon": {
    id: "alarm.map-profile.alarm-icon",
    defaultMessage: "Alarm icon",
  },
  "alarm.map-profile.rotated": {
    id: "alarm.map-profile.rotated",
    defaultMessage: "Indicate heading",
  },
  "alarm.map-profile.line-thickness": {
    id: "alarm.map-profile.line-thickness",
    defaultMessage: "Lin thickness",
  },
  "alarm.map-profile.line-style": {
    id: "alarm.map-profile.line-style",
    defaultMessage: "Line style",
  },
  "alarm.map-profile.icon-spacing": {
    id: "alarm.map-profile.icon-spacing",
    defaultMessage: "Distance beteen icons",
  },
  "alarm-cdec-view.show-on-map": {
    id: "alarm-cdec-view.show-on-map",
    defaultMessage: "Show on map",
  },
  "alarm-cdec-view.show-on-chart": {
    id: "alarm-cdec-view.show-on-chart",
    defaultMessage: "Show on chart",
  },
  "alarm.poi.object-type": {
    id: "alarm.poi.object-type",
    defaultMessage: "Object",
  },
  "alarm.groundplan.north": {
    id: "alarm.groundplan.north",
    defaultMessage: "North",
  },
  "alarm.groundplan.east": {
    id: "alarm.groundplan.east",
    defaultMessage: "East",
  },
  "alarm.groundplan.south": {
    id: "alarm.groundplan.south",
    defaultMessage: "South",
  },
  "alarm.groundplan.west": {
    id: "alarm.groundplan.west",
    defaultMessage: "West",
  },
  "alarm.groundplan.rotation": {
    id: "alarm.groundplan.rotation",
    defaultMessage: "Rotation",
  },
  "alarm.groundplan.upload.description": {
    id: "alarm.groundplan.upload.description",
    defaultMessage: "Upload groundplan from file",
  },
  "alarm.operator.login": {
    id: "alarm.operator.login",
    defaultMessage: "Login name",
  },
  "alarm.operator.employee.employee-id": {
    id: "alarm.operator.employee.employee-id",
    defaultMessage: "Personal ID",
  },
  "alarm.operator.dispatcher-login-right": {
    id: "alarm.operator.dispatcher-login-right",
    defaultMessage: "Dispatcher login",
  },
  "alarm.operator.password-change-right": {
    id: "alarm.operator.password-change-right",
    defaultMessage: "Change password",
  },
  "alarm.operator.object-status-overview-right": {
    id: "alarm.operator.object-status-overview-right",
    defaultMessage: "Object state",
  },
  "alarm.operator.customer-statistics-right": {
    id: "alarm.operator.customer-statistics-right",
    defaultMessage: "Customer statistics right",
  },
  "alarm.operator.intervention-query-right": {
    id: "alarm.operator.intervention-query-right",
    defaultMessage: "Intervention query",
  },
  "alarm.operator.regular-reports-right": {
    id: "alarm.operator.regular-reports-right",
    defaultMessage: "Regular event reports",
  },
  "alarm.operator.technical-configuration-right": {
    id: "alarm.operator.technical-configuration-right",
    defaultMessage: "Technical configuration",
  },
  "alarm.operator.partner-configuration-right": {
    id: "alarm.operator.partner-configuration-right",
    defaultMessage: "Partner configuration",
  },
  "alarm.cdec-filter.event-codes-valid-format": {
    id: "alarm.cdec-filter.event-codes-valid-format",
    defaultMessage: "Comma event codes, e.g. 1603,F010.",
  },
  "alarm.customer.disabled-events": {
    id: "alarm.customer.disabled-events",
    defaultMessage: "Disabled events",
  },
  "alarm.customer.edit-disabled-event": {
    id: "alarm.customer.edit-disabled-event",
    defaultMessage: "Edit disabled event",
  },
  "alarm.customer.add-disabled-event": {
    id: "alarm.customer.add-disabled-event",
    defaultMessage: "Add disabled event",
  },
  "alarm-event.description": {
    id: "alarm-event.description",
    defaultMessage: "Description",
  },
  "alarm-event.fields": {
    id: "alarm-event.fields",
    defaultMessage: "Data fields",
  },
  "alarm-event.source": {
    id: "alarm-event.source",
    defaultMessage: "Source",
  },
  "alarm-event.new-field": {
    id: "alarm-event.new-field",
    defaultMessage: "New data field",
  },
  "alarm-event.copy-into-other-code-table": {
    id: "alarm-event.copy-into-other-code-table",
    defaultMessage: "Copy into an other code table",
  },
  "alarm-event.copy": {
    id: "alarm-event.copy",
    defaultMessage: "Copy",
  },
  "alarm-event.field-text": {
    id: "alarm-event.field-text",
    defaultMessage: "Text data",
  },
  "alarm-event.field-measurement": {
    id: "alarm-event.field-measurement",
    defaultMessage: "Measurement",
  },
  "alarm-event.field-attachement": {
    id: "alarm-event.field-attachement",
    defaultMessage: "Attachement",
  },
  "alarm-event.field-counter": {
    id: "alarm-event.field-counter",
    defaultMessage: "Counter value",
  },
  "alarm-event.nth-text-field": {
    id: "alarm-event.nth-text-field",
    defaultMessage:
      "{count, selectordinal, one{#st} two{#nd} few{#rd} other{#th}} text field",
  },
  "alarm-event.zone-number": {
    id: "alarm-event.zone-number",
    defaultMessage: "Zone number",
  },
  "alarm-event.partition-number": {
    id: "alarm-event.partition-number",
    defaultMessage: "Partition number",
  },
  "alarm-event.parameter": {
    id: "alarm-event.parameter",
    defaultMessage: "Parameter",
  },
  "alarm-event.unknown-type": {
    id: "alarm-event.unknown-type",
    defaultMessage: "Unknown type",
  },
  "alarm-event.copy-successful": {
    id: "alarm-event.copy-successful",
    defaultMessage: "Copy successful",
  },
  "alarm-event.copy-unsuccessful": {
    id: "alarm-event.copy-unsuccessful",
    defaultMessage: "Copy unsuccessful",
  },
  "alarm.rfid-token.number": {
    id: "alarm.rfid-token.number",
    defaultMessage: "Number",
  },
  "alarm-category.intervention-needed": {
    id: "alarm-category.intervention-needed",
    defaultMessage: "Intervention needed",
  },
  "alarm.customer-action-groups.new-action-group": {
    id: "alarm.customer-action-groups.new-action-group",
    defaultMessage: "New action plan",
  },
  "alarm.customer-action-groups.notifiable-type": {
    id: "alarm.customer-action-groups.notifiable-type",
    defaultMessage: "Contact type",
  },
  "alarm.customer-action-groups.notifiable": {
    id: "alarm.customer-action-groups.notifiable",
    defaultMessage: "Contact",
  },
  "alarm.customer-action-groups.channel": {
    id: "alarm.customer-action-groups.channel",
    defaultMessage: "Channel",
  },
  "alarm.customer-action-groups.action-groups": {
    id: "alarm.customer-action-groups.action-groups",
    defaultMessage: "Action plans",
  },
  "alarm.customer-action-groups.notify-customer": {
    id: "alarm.customer-action-groups.notify-customer",
    defaultMessage: "Notify the customer",
  },
  "alarm.customer-action-groups.notify-patrol": {
    id: "alarm.customer-action-groups.notify-patrol",
    defaultMessage: "Notify a patrol service",
  },
  "alarm.customer-action-groups.notify-authority": {
    id: "alarm.customer-action-groups.notify-authority",
    defaultMessage: "Notify the authorities",
  },
  "alarm.customer-action-groups.notify-installer": {
    id: "alarm.customer-action-groups.notify-installer",
    defaultMessage: "Notify a technician",
  },
  "alarm.customer-action-groups.text-message": {
    id: "alarm.customer-action-groups.text-message",
    defaultMessage: "Send SMS",
  },
  "alarm.customer-action-groups.send-email": {
    id: "alarm.customer-action-groups.send-email",
    defaultMessage: "Send E-mail",
  },
  "alarm.subaction-item.email": {
    id: "alarm.subaction-item.email",
    defaultMessage: "Email",
  },
  "alarm.subaction-item.missing-notified": {
    id: "alarm.subaction-item.missing-notified",
    defaultMessage: "(Missing contact)",
  },
  "alarm.subaction-item.unnamed-notified": {
    id: "alarm.subaction-item.unnamed-notified",
    defaultMessage: "(Unnamed contact)",
  },
  "alarm.subaction-item.other-channel": {
    id: "alarm.subaction-item.other-channel",
    defaultMessage: "(Other channel)",
  },
  "alarm.subaction-item.missing-channel": {
    id: "alarm.subaction-item.missing-channel",
    defaultMessage: "(Missing channel)",
  },
  "alarm.customer-date-code-overrides.date-code-overrides": {
    id: "alarm.customer-date-code-overrides.date-code-overrides",
    defaultMessage: "Date code overrides",
  },
  "alarm.customer-date-code-overrides.edit-date-code-override": {
    id: "alarm.customer-date-code-overrides.edit-date-code-override",
    defaultMessage: "Edit override",
  },
  "alarm.customer-date-code-overrides.new-date-code-override": {
    id: "alarm.customer-date-code-overrides.new-date-code-override",
    defaultMessage: "New override",
  },
  "alarm.customer-event-storno.event-storno": {
    id: "alarm.customer-event-storno.event-storno",
    defaultMessage: "Event storno",
  },
  "alarm.customer-event-storno.edit-event-storno": {
    id: "alarm.customer-event-storno.edit-event-storno",
    defaultMessage: "Edit event storno",
  },
  "alarm.customer-event-storno.new-event-storno": {
    id: "alarm.customer-event-storno.new-event-storno",
    defaultMessage: "New event storno",
  },
  "alarm.customer-event-storno.zone": {
    id: "alarm.customer-event-storno.zone",
    defaultMessage: "Zone",
  },
  "alarm.customer-event-storno.alert-event-code": {
    id: "alarm.customer-event-storno.alert-event-code",
    defaultMessage: "Alert event code",
  },
  "alarm.customer-event-storno.restore-event-code": {
    id: "alarm.customer-event-storno.restore-event-code",
    defaultMessage: "Restore event code",
  },
  "alarm.customer-notifiable-persons.notifiables": {
    id: "alarm.customer-notifiable-persons.notifiables",
    defaultMessage: "Notifiables",
  },
  "alarm.customer-notifiable-persons.edit-notifiable": {
    id: "alarm.customer-notifiable-persons.edit-notifiable",
    defaultMessage: "Edit notifiable",
  },
  "alarm.customer-notifiable-persons.new-notifiable": {
    id: "alarm.customer-notifiable-persons.new-notifiable",
    defaultMessage: "New notifiable",
  },
  "alarm.customer-partitions.partitions": {
    id: "alarm.customer-partitions.partitions",
    defaultMessage: "Partitions",
  },
  "alarm.customer-partitions.edit-partition": {
    id: "alarm.customer-partitions.edit-partition",
    defaultMessage: "Edit partition",
  },
  "alarm.customer-partitions.new-partition": {
    id: "alarm.customer-partitions.new-partition",
    defaultMessage: "New partititon",
  },
  "alarm.customer-rfid-tokens.rfid-tokens": {
    id: "alarm.customer-rfid-tokens.rfid-tokens",
    defaultMessage: "RFID token",
  },
  "alarm.customer-rfid-tokens.edit-rfid-token": {
    id: "alarm.customer-rfid-tokens.edit-rfid-token",
    defaultMessage: "Edit RFID token",
  },
  "alarm.customer-rfid-tokens.new-rfid-token": {
    id: "alarm.customer-rfid-tokens.new-rfid-token",
    defaultMessage: "New RFID token",
  },
  "alarm.customer-users.new-user": {
    id: "alarm.customer-users.new-user",
    defaultMessage: "New user",
  },
  "alarm.customer-zones.new-zone": {
    id: "alarm.customer-zones.new-zone",
    defaultMessage: "New zone",
  },
  "alarm.customer-zones.sensor": {
    id: "alarm.customer-zones.sensor",
    defaultMessage: "Sensor",
  },
  "alarm.customer-zones.unknown": {
    id: "alarm.customer-zones.unknown",
    defaultMessage: "Unkown",
  },
  "mbp-alarm-components.check-firesignal-configurations": {
    id: "mbp-alarm-components.check-firesignal-configurations",
    defaultMessage: "Check firesignal configurations",
  },
  "alarm-components.last-sectors.refresh": {
    id: "alarm-components.last-sectors.refresh",
    defaultMessage: "Refresh",
  },
  "date.format.date-time": {
    id: "date.format.date-time",
    defaultMessage: "YYYY/MM/DD HH:mm:ss",
  },
  "date.format.date": {
    id: "date.format.date",
    defaultMessage: "YYYY/MM/DD",
  },
  "date.first-day-of-week": {
    id: "date.first-day-of-week",
    defaultMessage: "0",
  },
  "date.invalid": {
    id: "date.invalid",
    defaultMessage: "Invalid date.",
  },
  "date.required": {
    id: "date.required",
    defaultMessage: "Required",
  },
  accept: {
    id: "accept",
    defaultMessage: "Accept",
  },
  "gps.vehicle-groups": {
    id: "gps.vehicle-groups",
    defaultMessage: "Vehicle groups",
  },
  "gps.units": {
    id: "gps.units",
    defaultMessage: "Units",
  },
  "gps.drivers": {
    id: "gps.drivers",
    defaultMessage: "Drivers",
  },
  "gps.driver-groups": {
    id: "gps.driver-groups",
    defaultMessage: "Driver groups",
  },
  users: {
    id: "users",
    defaultMessage: "Users",
  },
  "gps.user-groups": {
    id: "gps.user-groups",
    defaultMessage: "User groups",
  },
  "gps.companies": {
    id: "gps.companies",
    defaultMessage: "Companies",
  },
  "gps.company-groups": {
    id: "gps.company-groups",
    defaultMessage: "Company groups",
  },
  icons: {
    id: "icons",
    defaultMessage: "Icons",
  },
  "gps.pois": {
    id: "gps.pois",
    defaultMessage: "Areas",
  },
  "map-profiles": {
    id: "map-profiles",
    defaultMessage: "Map profiles",
  },
  identifier: {
    id: "identifier",
    defaultMessage: "Identifier",
  },
  name: {
    id: "name",
    defaultMessage: "Name",
  },
  comment: {
    id: "comment",
    defaultMessage: "Comment",
  },
  company: {
    id: "company",
    defaultMessage: "Company",
  },
  active: {
    id: "active",
    defaultMessage: "Active",
  },
  "gps.vehicle": {
    id: "gps.vehicle",
    defaultMessage: "Vehicle",
  },
  "gps.group-name": {
    id: "gps.group-name",
    defaultMessage: "Group name",
  },
  color: {
    id: "color",
    defaultMessage: "Color",
  },
  "gps.position-data": {
    id: "gps.position-data",
    defaultMessage: "Vehicle position data",
  },
  "gps.gps": {
    id: "gps.gps",
    defaultMessage: "GPS",
  },
  status: {
    id: "status",
    defaultMessage: "Status",
  },
  "fuel-consumption": {
    id: "fuel-consumption",
    defaultMessage: "Fuel consumption",
  },
  "gps.unitid": {
    id: "gps.unitid",
    defaultMessage: "Unit ID",
  },
  address: {
    id: "address",
    defaultMessage: "Address",
  },
  "gps.usage.business": {
    id: "gps.usage.business",
    defaultMessage: "Business",
  },
  "gps.usage.private": {
    id: "gps.usage.private",
    defaultMessage: "Private",
  },
  "gps.movement-time": {
    id: "gps.movement-time",
    defaultMessage: "Moving duration",
  },
  "gps.immobility-time": {
    id: "gps.immobility-time",
    defaultMessage: "Stopped duration",
  },
  "gps.waybills": {
    id: "gps.waybills",
    defaultMessage: "Waybills",
  },
  "gps.vehicle-group": {
    id: "gps.vehicle-group",
    defaultMessage: "Vehicle group",
  },
  "gps.driver": {
    id: "gps.driver",
    defaultMessage: "Driver",
  },
  filter: {
    id: "filter",
    defaultMessage: "Filter",
  },
  "gps.distance-covered": {
    id: "gps.distance-covered",
    defaultMessage: "Distance covered",
  },
  "gps.n-th-group": {
    id: "gps.n-th-group",
    defaultMessage:
      "{n, selectordinal, one{#st} two{#nd} few{#rd} other{#th}} group",
  },
  "gps.company-group": {
    id: "gps.company-group",
    defaultMessage: "Company group",
  },
  icon: {
    id: "icon",
    defaultMessage: "Icon",
  },
  manufacturer: {
    id: "manufacturer",
    defaultMessage: "Manufacturer",
  },
  type: {
    id: "type",
    defaultMessage: "Type",
  },
  "gps.module-type": {
    id: "gps.module-type",
    defaultMessage: "Module type",
  },
  unitid: {
    id: "unitid",
    defaultMessage: "Helios ID",
  },
  username: {
    id: "username",
    defaultMessage: "Username",
  },
  "alarm.customer": {
    id: "alarm.customer",
    defaultMessage: "Customer",
  },
  event: {
    id: "event",
    defaultMessage: "Event",
  },
  code: {
    id: "code",
    defaultMessage: "Code",
  },
  admin: {
    id: "admin",
    defaultMessage: "Admin",
  },
  "alarm.operator": {
    id: "alarm.operator",
    defaultMessage: "Operator",
  },
  "phone-number": {
    id: "phone-number",
    defaultMessage: "Phone",
  },
  "mobile-number": {
    id: "mobile-number",
    defaultMessage: "Mobile",
  },
  email: {
    id: "email",
    defaultMessage: "E-mail",
  },
  password: {
    id: "password",
    defaultMessage: "Password",
  },
  number: {
    id: "number",
    defaultMessage: "Number",
  },
  place: {
    id: "place",
    defaultMessage: "Place",
  },
  arrived: {
    id: "arrived",
    defaultMessage: "Arrived",
  },
  description: {
    id: "description",
    defaultMessage: "Description",
  },
  time: {
    id: "time",
    defaultMessage: "Time",
  },
  "descr-path": {
    id: "descr-path",
    defaultMessage: "descr.en-gb",
  },
  "alarm.event-category-short": {
    id: "alarm.event-category-short",
    defaultMessage: "Category",
  },
  "alarm.customers": {
    id: "alarm.customers",
    defaultMessage: "Customers",
  },
  "alarm.event-query": {
    id: "alarm.event-query",
    defaultMessage: "Event query",
  },
  "alarm.status-overview": {
    id: "alarm.status-overview",
    defaultMessage: "Status overview",
  },
  "alarm.customer-groups": {
    id: "alarm.customer-groups",
    defaultMessage: "Customer groups",
  },
  "alarm.firesignals": {
    id: "alarm.firesignals",
    defaultMessage: "Firesignals",
  },
  "alarm.rfid-cards": {
    id: "alarm.rfid-cards",
    defaultMessage: "RFID cards",
  },
  administration: {
    id: "administration",
    defaultMessage: "Administration",
  },
  "alarm.code-tables": {
    id: "alarm.code-tables",
    defaultMessage: "Code tables",
  },
  "alarm.event-types": {
    id: "alarm.event-types",
    defaultMessage: "Event types",
  },
  "alarm.event-categories": {
    id: "alarm.event-categories",
    defaultMessage: "Event categories",
  },
  "alarm.sounds": {
    id: "alarm.sounds",
    defaultMessage: "Sounds",
  },
  "alarm.object-types": {
    id: "alarm.object-types",
    defaultMessage: "Object types",
  },
  "alarm.pois": {
    id: "alarm.pois",
    defaultMessage: "POIs",
  },
  "alarm.groundplans": {
    id: "alarm.groundplans",
    defaultMessage: "Ground plans",
  },
  "alarm.sensor-types": {
    id: "alarm.sensor-types",
    defaultMessage: "Sensor types",
  },
  "alarm.zone-types": {
    id: "alarm.zone-types",
    defaultMessage: "Zone types",
  },
  "alarm.equipment-types": {
    id: "alarm.equipment-types",
    defaultMessage: "Equipment types",
  },
  "alarm.battery-types": {
    id: "alarm.battery-types",
    defaultMessage: "Battery types",
  },
  "alarm.sounder-types": {
    id: "alarm.sounder-types",
    defaultMessage: "Sounder types",
  },
  "alarm.authorities": {
    id: "alarm.authorities",
    defaultMessage: "Authorities",
  },
  "alarm.installers": {
    id: "alarm.installers",
    defaultMessage: "Installers",
  },
  "alarm.operators": {
    id: "alarm.operators",
    defaultMessage: "Operators",
  },
  "alarm.map-sectors": {
    id: "alarm.map-sectors",
    defaultMessage: "Map sectors",
  },
  "alarm.fix-beacons": {
    id: "alarm.fix-beacons",
    defaultMessage: "Fix beacons",
  },
  "alarm.moving-receivers": {
    id: "alarm.moving-receivers",
    defaultMessage: "Moving receivers",
  },
  "alarm.fix-receivers": {
    id: "alarm.fix-receivers",
    defaultMessage: "Fix receivers",
  },
  "alarm.moving-beacons": {
    id: "alarm.moving-beacons",
    defaultMessage: "Moving beacons",
  },
  "alarm.last-sectors": {
    id: "alarm.last-sectors",
    defaultMessage: "Last sectors",
  },
  "alarm.default-queries": {
    id: "alarm.default-queries",
    defaultMessage: "Default queries",
  },
  "alarm.regular-reports": {
    id: "alarm.regular-reports",
    defaultMessage: "Regular report",
  },
  "alarm.map-tracking": {
    id: "alarm.map-tracking",
    defaultMessage: "Map tracking",
  },
  "alarm.other": {
    id: "alarm.other",
    defaultMessage: "Other",
  },
  settings: {
    id: "settings",
    defaultMessage: "Settings",
  },
  export: {
    id: "export",
    defaultMessage: "Export",
  },
  import: {
    id: "import",
    defaultMessage: "Import",
  },
  delete: {
    id: "delete",
    defaultMessage: "Delete",
  },
  "alarm.check": {
    id: "alarm.check",
    defaultMessage: "Check",
  },
  upload: {
    id: "upload",
    defaultMessage: "Upload",
  },
  "alarm.account": {
    id: "alarm.account",
    defaultMessage: "Account",
  },
  "alarm.command": {
    id: "alarm.command",
    defaultMessage: "Command",
  },
  rfid: {
    id: "rfid",
    defaultMessage: "RFID",
  },
  "alarm.trzs": {
    id: "alarm.trzs",
    defaultMessage: "External ID",
  },
  inactive: {
    id: "inactive",
    defaultMessage: "Inactive",
  },
  "alarm.code-table": {
    id: "alarm.code-table",
    defaultMessage: "Code table",
  },
  next: {
    id: "next",
    defaultMessage: "Next",
  },
  "alarm.clone": {
    id: "alarm.clone",
    defaultMessage: "Clone",
  },
  no: {
    id: "no",
    defaultMessage: "No",
  },
  yes: {
    id: "yes",
    defaultMessage: "Yes",
  },
  "alarm.customer-group": {
    id: "alarm.customer-group",
    defaultMessage: "Customer group",
  },
  "alarm.installer": {
    id: "alarm.installer",
    defaultMessage: "Installer",
  },
  "alarm.base-coordinates": {
    id: "alarm.base-coordinates",
    defaultMessage: "Base coordinates",
  },
  distance: {
    id: "distance",
    defaultMessage: "Distance",
  },
  "alarm.contract-number": {
    id: "alarm.contract-number",
    defaultMessage: "Contract number",
  },
  "alarm.billing-id": {
    id: "alarm.billing-id",
    defaultMessage: "Billing ID",
  },
  all: {
    id: "all",
    defaultMessage: "All",
  },
  "alarm.interval": {
    id: "alarm.interval",
    defaultMessage: "Interval",
  },
  "alarm.layer-id": {
    id: "alarm.layer-id",
    defaultMessage: "Layer ID",
  },
  "alarm.floor": {
    id: "alarm.floor",
    defaultMessage: "Floor",
  },
  "alarm.sector-number": {
    id: "alarm.sector-number",
    defaultMessage: "Sector number",
  },
  "alarm.beacon-minor": {
    id: "alarm.beacon-minor",
    defaultMessage: "Minor",
  },
  "alarm.sector": {
    id: "alarm.sector",
    defaultMessage: "Sector",
  },
  latitude: {
    id: "latitude",
    defaultMessage: "Latitude",
  },
  longitude: {
    id: "longitude",
    defaultMessage: "Longitude",
  },
  position: {
    id: "position",
    defaultMessage: "Position",
  },
  "alarm.beacon-major": {
    id: "alarm.beacon-major",
    defaultMessage: "Major",
  },
  "alarm.beacon-uuid": {
    id: "alarm.beacon-uuid",
    defaultMessage: "UUID",
  },
  "alarm.tx-multiplier": {
    id: "alarm.tx-multiplier",
    defaultMessage: "Signal multiplier",
  },
  "alarm.last-activity": {
    id: "alarm.last-activity",
    defaultMessage: "Last activity",
  },
  "alarm.last-seen": {
    id: "alarm.last-seen",
    defaultMessage: "Last seen",
  },
  "alarm.rssi-multiplier": {
    id: "alarm.rssi-multiplier",
    defaultMessage: "Signal multiplier",
  },
  "alarm.beacon-algorithm-interval": {
    id: "alarm.beacon-algorithm-interval",
    defaultMessage: "Algorithm interval",
  },
  "alarm.last-sector": {
    id: "alarm.last-sector",
    defaultMessage: "Last sector",
  },
  "last-position": {
    id: "last-position",
    defaultMessage: "Last position",
  },
  "alarm.beacon-algorithms.avg": {
    id: "alarm.beacon-algorithms.avg",
    defaultMessage: "Average",
  },
  "alarm.beacon-algorithms.max": {
    id: "alarm.beacon-algorithms.max",
    defaultMessage: "Nearest",
  },
  "no-data": {
    id: "no-data",
    defaultMessage: "No data",
  },
  none: {
    id: "none",
    defaultMessage: "None",
  },
  "apply-filter": {
    id: "apply-filter",
    defaultMessage: "Apply filter",
  },
  "filter-off": {
    id: "filter-off",
    defaultMessage: "-",
  },
  "database-id": {
    id: "database-id",
    defaultMessage: "Database ID",
  },
  "alarm.event-code-valid-format": {
    id: "alarm.event-code-valid-format",
    defaultMessage: "Four digit hexadecimal number, e.g. 001F.",
  },
  "alarm.incoming-events": {
    id: "alarm.incoming-events",
    defaultMessage: "Incoming events",
  },
  close: {
    id: "close",
    defaultMessage: "Close",
  },
  open: {
    id: "open",
    defaultMessage: "Open",
  },
  "alarm.armed": {
    id: "alarm.armed",
    defaultMessage: "Armed",
  },
  "alarm.event-category-long": {
    id: "alarm.event-category-long",
    defaultMessage: "Event category",
  },
  "alarm.unknown-customer": {
    id: "alarm.unknown-customer",
    defaultMessage: "Unknown customer",
  },
  "last-1-hour": {
    id: "last-1-hour",
    defaultMessage: "Last 1 hour",
  },
  today: {
    id: "today",
    defaultMessage: "Today",
  },
  "last-1-day": {
    id: "last-1-day",
    defaultMessage: "Last 1 day",
  },
  "this-week": {
    id: "this-week",
    defaultMessage: "This week",
  },
  "last-n-weeks": {
    id: "last-n-weeks",
    defaultMessage: "Last {numWeeks, plural, =1 {week} other {# weeks}}",
  },
  "this-month": {
    id: "this-month",
    defaultMessage: "This month",
  },
  "last-n-months": {
    id: "last-n-months",
    defaultMessage: "Last {numMonths, plural, =1 {month} other {# months}}",
  },
  "time-from": {
    id: "time-from",
    defaultMessage: "From",
  },
  "time-to": {
    id: "time-to",
    defaultMessage: "To",
  },
  "alarm.event-code": {
    id: "alarm.event-code",
    defaultMessage: "Event code",
  },
  "alarm.equipment-type": {
    id: "alarm.equipment-type",
    defaultMessage: "Equipment type",
  },
  "alarm.object-type": {
    id: "alarm.object-type",
    defaultMessage: "Object type",
  },
  download: {
    id: "download",
    defaultMessage: "Download",
  },
  "current-position": {
    id: "current-position",
    defaultMessage: "Current position",
  },
  "base-icon": {
    id: "base-icon",
    defaultMessage: "Base icon",
  },
  "previous-positions": {
    id: "previous-positions",
    defaultMessage: "Previous positions",
  },
  "map-profile.fade": {
    id: "map-profile.fade",
    defaultMessage: "Fade",
  },
  "map-profile.connecting-line": {
    id: "map-profile.connecting-line",
    defaultMessage: "Connecting line",
  },
  "map-profile.custom-line-style": {
    id: "map-profile.custom-line-style",
    defaultMessage: "Custom line style",
  },
  "map-profile.line-arrow": {
    id: "map-profile.line-arrow",
    defaultMessage: "Indicate heading with arrow",
  },
  "map-profile.connecting-icon": {
    id: "map-profile.connecting-icon",
    defaultMessage: "Connecting icon",
  },
  "description-name": {
    id: "description-name",
    defaultMessage: "Name",
  },
  permissions: {
    id: "permissions",
    defaultMessage: "Permissions",
  },
  "alarm.permissions.can-intervene": {
    id: "alarm.permissions.can-intervene",
    defaultMessage: "Intervention",
  },
  "alarm.permissions.customer-manager-right": {
    id: "alarm.permissions.customer-manager-right",
    defaultMessage: "Customer manager",
  },
  "alarm.permissions.modify": {
    id: "alarm.permissions.modify",
    defaultMessage: "Modify",
  },
  "alarm.sound": {
    id: "alarm.sound",
    defaultMessage: "Sound",
  },
  "alarm.priority": {
    id: "alarm.priority",
    defaultMessage: "Priority",
  },
  create: {
    id: "create",
    defaultMessage: "Create",
  },
  "alarm.call": {
    id: "alarm.call",
    defaultMessage: "Call",
  },
  "alarm.new-subaction": {
    id: "alarm.new-subaction",
    defaultMessage: "New sub-task",
  },
  "alarm.date-code": {
    id: "alarm.date-code",
    defaultMessage: "Date code",
  },
  "alarm.role": {
    id: "alarm.role",
    defaultMessage: "Role",
  },
  "edit-user": {
    id: "edit-user",
    defaultMessage: "Edit user",
  },
  "alarm.zones": {
    id: "alarm.zones",
    defaultMessage: "Zones",
  },
  "alarm.edit-zone": {
    id: "alarm.edit-zone",
    defaultMessage: "Edit zone",
  },
  "alarm.temporary-inactivity": {
    id: "alarm.temporary-inactivity",
    defaultMessage: "Temporary inactivity",
  },
  coordinates: {
    id: "coordinates",
    defaultMessage: "Coordinates",
  },
  now: {
    id: "now",
    defaultMessage: "Now",
  },
  "alarm.rest-details": {
    id: "alarm.rest-details",
    defaultMessage: "Details...",
  },
  "mbp-alarm-operator.disable-event-code-conformation-question": {
    id: "mbp-alarm-operator.disable-event-code-conformation-question",
    defaultMessage:
      "Are you sure you want to inaactivate {account} customer's {eventCode} events? You can re-activate events in the customer manager.",
  },
  "operator-home-page.close-many-interventions": {
    id: "operator-home-page.close-many-interventions",
    defaultMessage: "Bulk intervention",
  },
  "alarm.operator-smtp-configs": {
    id: "alarm.operator-smtp-configs",
    defaultMessage: "SMTP settings",
  },
  "alarm.edit-operator-smtp-config": {
    id: "alarm.edit-operator-smtp-config",
    defaultMessage: "Edit SMTP settings",
  },
  "alarm.create-operator-smtp-config": {
    id: "alarm.create-operator-smtp-config",
    defaultMessage: "New SMTP settings",
  },
  host: {
    id: "host",
    defaultMessage: "Host",
  },
  port: {
    id: "port",
    defaultMessage: "Port",
  },
  "alarm.smtp-secure": {
    id: "alarm.smtp-secure",
    defaultMessage: "Secure (TLS/SSL)",
  },
  "alarm.smtp-from-email": {
    id: "alarm.smtp-from-email",
    defaultMessage: "From email",
  },
  "operator-home-page.enable-audio": {
    id: "operator-home-page.enable-audio",
    defaultMessage: "Enable audio",
  },
  "operator-home-page.enable-audio-description": {
    id: "operator-home-page.enable-audio-description",
    defaultMessage:
      "The browser requires user interaction before it enables audio playback.",
  },
  "alarm.patrol": {
    id: "alarm.patrol",
    defaultMessage: "Patrolling",
  },
  "alarm.patrollings": {
    id: "alarm.patrollings",
    defaultMessage: "Patrols",
  },
  "alarm.patrolling-edit.title": {
    id: "alarm.patrolling-edit.title",
    defaultMessage: "Edit patrol",
  },
  "alarm.patrolling-create.title": {
    id: "alarm.patrolling-create.title",
    defaultMessage: "New patrol",
  },
  "alarm.patrolling.name": {
    id: "alarm.patrolling.name",
    defaultMessage: "Patrol name",
  },
  "alarm.patrolling.timezone": {
    id: "alarm.patrolling.timezone",
    defaultMessage: "Timezone",
  },
  "alarm.patrolling.open-copy-dialog": {
    id: "alarm.patrolling.open-copy-dialog",
    defaultMessage: "Clone this patrol",
  },
  "alarm.patrolling.choose-customer": {
    id: "alarm.patrolling.choose-customer",
    defaultMessage: "Choose a customer before continuing.",
  },
  "alarm.patrolling.copy": {
    id: "alarm.patrolling.copy",
    defaultMessage: "Copy",
  },
  "alarm.patrolling.routes": {
    id: "alarm.patrolling.routes",
    defaultMessage: "Routes",
  },
  "alarm.patrolling.new-route": {
    id: "alarm.patrolling.new-route",
    defaultMessage: "New route",
  },
  "alarm.patrolling.early-start-enabled": {
    id: "alarm.patrolling.early-start-enabled",
    defaultMessage:
      "Enable early starts. The patrol can begin in the intervals before the automatic start.",
  },
  "alarm.route.route-name": {
    id: "alarm.route.route-name",
    defaultMessage:
      "{n, selectordinal, one{#st} two{#nd} few{#rd} other{#th}} route",
  },
  "alarm.route.remove-route": {
    id: "alarm.route.remove-route",
    defaultMessage: "Delete route",
  },
  "alarm.route.name": {
    id: "alarm.route.name",
    defaultMessage: "Route name",
  },
  "alarm.route.max-duration": {
    id: "alarm.route.max-duration",
    defaultMessage: "Route max duration",
  },
  "alarm.route.no-tokens": {
    id: "alarm.route.no-tokens",
    defaultMessage:
      "There aren't any RFID tokens registered for this customer.",
  },
  "alarm.route.tokens-info": {
    id: "alarm.route.tokens-info",
    defaultMessage:
      "Clicking on the RFID token's name will add it to the route.",
  },
  "alarm.patrolling.shift-change": {
    id: "alarm.patrolling.shift-change",
    defaultMessage: "Shift change",
  },
  "alarm.patrolling.new-timetable": {
    id: "alarm.patrolling.new-timetable",
    defaultMessage: "New timetable",
  },
  "alarm.patrolling.toolbox-company": {
    id: "alarm.patrolling.toolbox-company",
    defaultMessage: "Toolbox company",
  },
  "alarm.patrolling.report-time": {
    id: "alarm.patrolling.report-time",
    defaultMessage: "Report time",
  },
  "alarm.patrolling.reports": {
    id: "alarm.patrolling.reports",
    defaultMessage: "Report recipients",
  },
  "alarm.timetable.timetable-name": {
    id: "alarm.timetable.timetable-name",
    defaultMessage:
      "{n, selectordinal, one{#st} two{#nd} few{#rd} other{#th}} timetable",
  },
  "alarm.timetable.name": {
    id: "alarm.timetable.name",
    defaultMessage: "Timetable name",
  },
  "alarm.timetable.remove-timetable": {
    id: "alarm.timetable.remove-timetable",
    defaultMessage: "Delete timetable",
  },
  "alarm.timetable.random": {
    id: "alarm.timetable.random",
    defaultMessage:
      "Randomized start. The patrol start will be scheduled once in each interval at a random offset.",
  },
  "alarm.timetable.daytype": {
    id: "alarm.timetable.daytype",
    defaultMessage: "Day type (daylight saving change)",
  },
  "alarm.timetables": {
    id: "alarm.timetables",
    defaultMessage: "Timetables",
  },
  "alarm.patrolling.calendar": {
    id: "alarm.patrolling.calendar",
    defaultMessage: "Calendar",
  },
  "alarm.calendar.year": {
    id: "alarm.calendar.year",
    defaultMessage: "Year",
  },
  "alarm.calendar.weekdays": {
    id: "alarm.calendar.weekdays",
    defaultMessage: "Weekdays",
  },
  "alarm.calendar.weekend": {
    id: "alarm.calendar.weekend",
    defaultMessage: "Weekends",
  },
  "alarm.calendar.monday": {
    id: "alarm.calendar.monday",
    defaultMessage: "Every Monday",
  },
  "alarm.calendar.tuesday": {
    id: "alarm.calendar.tuesday",
    defaultMessage: "Every Tuesday",
  },
  "alarm.calendar.wednesday": {
    id: "alarm.calendar.wednesday",
    defaultMessage: "Every Wednesday",
  },
  "alarm.calendar.thursday": {
    id: "alarm.calendar.thursday",
    defaultMessage: "Every Thursday",
  },
  "alarm.calendar.friday": {
    id: "alarm.calendar.friday",
    defaultMessage: "Every Friday",
  },
  "alarm.calendar.saturday": {
    id: "alarm.calendar.saturday",
    defaultMessage: "Every Saturday",
  },
  "alarm.calendar.sunday": {
    id: "alarm.calendar.sunday",
    defaultMessage: "Every Sunday",
  },
  "alarm.calendar.select": {
    id: "alarm.calendar.select",
    defaultMessage: "Select",
  },
  "alarm.calendar.deselect": {
    id: "alarm.calendar.deselect",
    defaultMessage: "Deselect",
  },
  "alarm.calendar.apply-timetable": {
    id: "alarm.calendar.apply-timetable",
    defaultMessage: "Apply",
  },
  "alarm.calendar.remove-selected": {
    id: "alarm.calendar.remove-selected",
    defaultMessage: "Clear selected days",
  },
  "alarm.calendar.remove-all-timetable": {
    id: "alarm.calendar.remove-all-timetable",
    defaultMessage: "Clear calendar",
  },
  "ararm.calendar-create-file": {
    id: "ararm.calendar-create-file",
    defaultMessage: "Create file on the server",
  },
  "alarm.calendar-file-info": {
    id: "alarm.calendar-file-info",
    defaultMessage: "Save all changes before creating the file!",
  },
  "alarm.calendar-file-success": {
    id: "alarm.calendar-file-success",
    defaultMessage: "File created successfully: {filename}",
  },
  "alarm.calendar-file-error": {
    id: "alarm.calendar-file-error",
    defaultMessage: "File creation failed!",
  },
  "alarm.calendar.day-select": {
    id: "alarm.calendar.day-select",
    defaultMessage: "Select days",
  },
  "alarm.route.filter-customergroup": {
    id: "alarm.route.filter-customergroup",
    defaultMessage: "Filter by customer groups",
  },
  "alarm.route.filter-token": {
    id: "alarm.route.filter-token",
    defaultMessage: "Customer with RFID tokens",
  },
  "alarm.route.index-label": {
    id: "alarm.route.index-label",
    defaultMessage:
      "{index, selectordinal, one{#st} two{#nd} few{#rd} other{#th}} checkpoint",
  },
  "alarm.route.patrol-paths": {
    id: "alarm.route.patrol-paths",
    defaultMessage: "Paths",
  },
  "alarm.route.part.min": {
    id: "alarm.route.part.min",
    defaultMessage: "Min. arrival time (minutes)",
  },
  "alarm.route.part.max": {
    id: "alarm.route.part.max",
    defaultMessage: "Max. arrival time (minutes)",
  },
  "alarm.patrolling.validate.name-missing": {
    id: "alarm.patrolling.validate.name-missing",
    defaultMessage: "Required: patrol name",
  },
  "alarm.patrolling.validate.routes-missing": {
    id: "alarm.patrolling.validate.routes-missing",
    defaultMessage: "Create at least one route!",
  },
  "alarm.patrolling.validate.timetables-missing": {
    id: "alarm.patrolling.validate.timetables-missing",
    defaultMessage: "Create at least one timetable!",
  },
  "alarm.patrolling.validate.calendar-days-missing": {
    id: "alarm.patrolling.validate.calendar-days-missing",
    defaultMessage: "Set a timetable for at least one day of the year!",
  },
  "alarm.patrolling.validate.route-name-missing": {
    id: "alarm.patrolling.validate.route-name-missing",
    defaultMessage: "Required: route name (#{nr})",
  },
  "alarm.patrolling.validate.timetable-name-missing": {
    id: "alarm.patrolling.validate.timetable-name-missing",
    defaultMessage: "Required: timetable name (#{nr})",
  },
  "alarm.patrolling.validate.route-paths-missing": {
    id: "alarm.patrolling.validate.route-paths-missing",
    defaultMessage: "The route is empty! ({name})",
  },
  "alarm.patrolling.validate.timetable-intervals-missing": {
    id: "alarm.patrolling.validate.timetable-intervals-missing",
    defaultMessage: "The timetable is empty! ({name})",
  },
  "alarm.patrolling.validate.timetable-overlaps": {
    id: "alarm.patrolling.validate.timetable-overlaps",
    defaultMessage:
      "There are overlapping intervals within the timetable, or an interval extends over the shift change! ({timetable})",
  },
  "alarm.patrolling.validate.wrong-timetable": {
    id: "alarm.patrolling.validate.wrong-timetable",
    defaultMessage: "Wrong timetable: {date} ({timetable})",
  },
  "alarm.patrolling.day-type.summer": {
    id: "alarm.patrolling.day-type.summer",
    defaultMessage: "Standard to DST ({from} → {to})",
  },
  "alarm.patrolling.day-type.winter": {
    id: "alarm.patrolling.day-type.winter",
    defaultMessage: "DST to Standard ({from} → {to})",
  },
  "alarm.patrol-reports": {
    id: "alarm.patrol-reports",
    defaultMessage: "Patrol overview",
  },
  start: {
    id: "start",
    defaultMessage: "Interval start",
  },
  "alarm.patrol-reports.scheduled-start": {
    id: "alarm.patrol-reports.scheduled-start",
    defaultMessage: "Scheduled: {date, date, tstz}",
  },
  "alarm.patrol-reports.scheduled-start-late": {
    id: "alarm.patrol-reports.scheduled-start-late",
    defaultMessage: "Scheduled: {date, date, tstz} (late)",
  },
  "alarm.patrol-reports.accepted-start": {
    id: "alarm.patrol-reports.accepted-start",
    defaultMessage: "Patrol start: {date, date, tstz}",
  },
  "alarm.patrol-reports.accepted-start-late": {
    id: "alarm.patrol-reports.accepted-start-late",
    defaultMessage: "Patrol start: {date, date, tstz} (late)",
  },
  "alarm.patrol-reports.read-out-of-turn": {
    id: "alarm.patrol-reports.read-out-of-turn",
    defaultMessage: "Arrived out of turn: {val, select, true{yes} other{no}}",
  },
  "alarm.patrol-reports.read-early": {
    id: "alarm.patrol-reports.read-early",
    defaultMessage: "Arrived early: {val, select, true{yes} other{no}}",
  },
  "alarm.patrol-reports.read-late": {
    id: "alarm.patrol-reports.read-late",
    defaultMessage: "Arrived late: {val, select, true{yes} other{no}}",
  },
  "alarm.patrol-reports.skipped": {
    id: "alarm.patrol-reports.skipped",
    defaultMessage: "Skipped: {val, select, true{yes} other{no}}",
  },
  "alarm.patrol-reports.suspended": {
    id: "alarm.patrol-reports.suspended",
    defaultMessage: "Patrol suspended: {val, select, true{yes} other{no}}",
  },
  "alarm.patroling-custom-timetables": {
    id: "alarm.patroling-custom-timetables",
    defaultMessage: "Custom timetables",
  },
  "alarm.timetable.default": {
    id: "alarm.timetable.default",
    defaultMessage: "Timetable created automatically based on <i>{name}</i>",
  },
  "alarm.timetable.custom": {
    id: "alarm.timetable.custom",
    defaultMessage: "Customize the automatically generated timetable",
  },
  "alarm.timetable.restore-to-default": {
    id: "alarm.timetable.restore-to-default",
    defaultMessage:
      "Restore the automatically generated timetable based on <i>{name}</i>",
  },
  "alarm.patrol-reports.interrupted": {
    id: "alarm.patrol-reports.interrupted",
    defaultMessage: "Interrupted",
  },
  "alarm.patrol-reports.completed": {
    id: "alarm.patrol-reports.completed",
    defaultMessage: "Completed",
  },
  "alarm.patrol-reports.not-scheduled": {
    id: "alarm.patrol-reports.not-scheduled",
    defaultMessage: "Not scheduled",
  },
  "alarm.patrol-reports.not-accepted": {
    id: "alarm.patrol-reports.not-accepted",
    defaultMessage: "Not started",
  },
  "alarm.patrol-reports.route": {
    id: "alarm.patrol-reports.route",
    defaultMessage: "Route",
  },
  "alarm.patrol-reports.no-read": {
    id: "alarm.patrol-reports.no-read",
    defaultMessage: "not arrived",
  },
  "alarm.patrolling.loading": {
    id: "alarm.patrolling.loading",
    defaultMessage: "Loading...",
  },
  "alarm.rfid-time-reports": {
    id: "alarm.rfid-time-reports",
    defaultMessage: "RFID Timesheet",
  },
  "alarm.rfid-time-report.acs-group": {
    id: "alarm.rfid-time-report.acs-group",
    defaultMessage: "RFID group",
  },
  "alarm.rfid-time-report.filename": {
    id: "alarm.rfid-time-report.filename",
    defaultMessage: "Timesheet name (filename)",
  },
  "alarm.rfid-time-report.select-customer": {
    id: "alarm.rfid-time-report.select-customer",
    defaultMessage: "Add new customer (site, device)",
  },
  "alarm.rfid-time-report.in-event": {
    id: "alarm.rfid-time-report.in-event",
    defaultMessage: "Entry (start) event",
  },
  "alarm.rfid-time-report.out-event": {
    id: "alarm.rfid-time-report.out-event",
    defaultMessage: "Exit (end) event",
  },
  "alarm.rfid-time-report.interval-offset": {
    id: "alarm.rfid-time-report.interval-offset",
    defaultMessage: "Interval offset",
  },
  "alarm.rfid-time-report.custom-name": {
    id: "alarm.rfid-time-report.custom-name",
    defaultMessage: "Custom name",
  },
  "alarm.rfid-time-report.error.event-in": {
    id: "alarm.rfid-time-report.error.event-in",
    defaultMessage:
      "The entry event is missing from some of the code tables: {list}",
  },
  "alarm.rfid-time-report.error.event-out": {
    id: "alarm.rfid-time-report.error.event-out",
    defaultMessage:
      "The exit event is missing from some of the code tables: {list}",
  },
  "alarm.rfid-time-report.validate": {
    id: "alarm.rfid-time-report.validate",
    defaultMessage: "Checking data",
  },
  "alarm.rfid-time-report.custom-interval": {
    id: "alarm.rfid-time-report.custom-interval",
    defaultMessage: "Export the timesheet with custom interval",
  },
  "alarm.rfid-time-report.default-interval": {
    id: "alarm.rfid-time-report.default-interval",
    defaultMessage: "Export the timesheet with the saved interval",
  },
  yesterday: {
    id: "yesterday",
    defaultMessage: "Yesterday",
  },
  "hu-hu": {
    id: "hu-hu",
    defaultMessage: "magyar",
  },
  "en-gb": {
    id: "en-gb",
    defaultMessage: "English",
  },
  "sk-sk": {
    id: "sk-sk",
    defaultMessage: "slovenský",
  },
  "last-known-position": {
    id: "last-known-position",
    defaultMessage: "Last known position",
  },
  "new-code-table": {
    id: "new-code-table",
    defaultMessage: "New code table",
  },
  "alarm.stock-monitoring-units": {
    id: "alarm.stock-monitoring-units",
    defaultMessage: "Stock monitoring units",
  },
  "alarm.stock-monitoring-dashboard": {
    id: "alarm.stock-monitoring-dashboard",
    defaultMessage: "Stock monitoring",
  },
  "show-only-alerts": {
    id: "show-only-alerts",
    defaultMessage: "Display alerts only",
  },
  "404-details": {
    id: "404-details",
    defaultMessage: "",
  },
  "stock-monitoring.products": {
    id: "stock-monitoring.products",
    defaultMessage: "Products",
  },
  "stock-monitoring.product": {
    id: "stock-monitoring.product",
    defaultMessage: "Product",
  },
  "stock-monitoring.products-new.title": {
    id: "stock-monitoring.products-new.title",
    defaultMessage: "New product",
  },
  "stock-monitoring.products-edit.title": {
    id: "stock-monitoring.products-edit.title",
    defaultMessage: "Edit product",
  },
  sku: {
    id: "sku",
    defaultMessage: "SKU",
  },
  size: {
    id: "size",
    defaultMessage: "Size",
  },
  image: {
    id: "image",
    defaultMessage: "Image",
  },
  "stock-monitoring.unit-edit.title": {
    id: "stock-monitoring.unit-edit.title",
    defaultMessage: "Edit stock monitoring unit",
  },
  "stock-monitoring.unit-new.title": {
    id: "stock-monitoring.unit-new.title",
    defaultMessage: "New stock monitoring unit",
  },
  "stock-monitoring.device": {
    id: "stock-monitoring.device",
    defaultMessage: "Device",
  },
  "stock-monitoring.product-type": {
    id: "stock-monitoring.product-type",
    defaultMessage: "Product type",
  },
  "stock-monitoring.product-types": {
    id: "stock-monitoring.product-types",
    defaultMessage: "Product types",
  },
  "stock-monitoring.product-type-edit.title": {
    id: "stock-monitoring.product-type-edit.title",
    defaultMessage: "Edit product type",
  },
  "stock-monitoring.product-type-new.title": {
    id: "stock-monitoring.product-type-new.title",
    defaultMessage: "New product type",
  },
  "stock-monitoring.new-layout-interval": {
    id: "stock-monitoring.new-layout-interval",
    defaultMessage: "New layout valid time period",
  },
  "stock-monitoring.validate.invalid-product-id": {
    id: "stock-monitoring.validate.invalid-product-id",
    defaultMessage: "Invalid product ID",
  },
  "auto-update-enabled": {
    id: "auto-update-enabled",
    defaultMessage: "Display current contents",
  },
  "missing-from-full": {
    id: "missing-from-full",
    defaultMessage: "Missing from full",
  },
  "logged-in": {
    id: "logged-in",
    defaultMessage: "Logged in as: {username}",
  },
  "client-version": {
    id: "client-version",
    defaultMessage: "Client version: {ver}",
  },
  region: {
    id: "region",
    defaultMessage: "Region",
  },
  "diff-view-enabled": {
    id: "diff-view-enabled",
    defaultMessage: "Missing product counter",
  },
  "alert-event-code": {
    id: "alert-event-code",
    defaultMessage: "Custom alert event",
  },
  "stock-monitoring-timestamp": {
    id: "stock-monitoring-timestamp",
    defaultMessage: "Updated",
  },
  "add-new-row-to-layout": {
    id: "add-new-row-to-layout",
    defaultMessage: "Add new shelf",
  },
  "add-new-product-to-shelf": {
    id: "add-new-product-to-shelf",
    defaultMessage: "Add new facing",
  },
  "stock-monitoring.facing-profile": {
    id: "stock-monitoring.facing-profile",
    defaultMessage: "Facing profile",
  },
  Commander: {
    id: "Commander",
    defaultMessage: "Commander",
  },
  Observer: {
    id: "Observer",
    defaultMessage: "Observer",
  },
  "Helios Toolbox": {
    id: "Helios Toolbox",
    defaultMessage: "Helios Toolbox",
  },
  preview: {
    id: "preview",
    defaultMessage: "Preview",
  },
  bool: {
    id: "bool",
    defaultMessage: "{val, select, true{yes} other{no}}",
  },
  "create-new-item": {
    id: "create-new-item",
    defaultMessage: "Create new...",
  },
  "reload-items": {
    id: "reload-items",
    defaultMessage: "Reload",
  },
  "sort-order": {
    id: "sort-order",
    defaultMessage: "Sorting...",
  },
  "sort-direction": {
    id: "sort-direction",
    defaultMessage: "Sort direction",
  },
  "interval-rules.min-event-count-per-interval": {
    id: "interval-rules.min-event-count-per-interval",
    defaultMessage: "Expected event count per interval",
  },
  "clone-code-table.tooltip": {
    id: "clone-code-table.tooltip",
    defaultMessage: "Duplicate this code table",
  },
  "download-code-table.tooltip": {
    id: "download-code-table.tooltip",
    defaultMessage: "Download the events as a CSV file",
  },
  "export-code-table.tooltip": {
    id: "export-code-table.tooltip",
    defaultMessage: "Export the code table to be imported into an other server",
  },
  "event-descr-missing-params": {
    id: "event-descr-missing-params",
    defaultMessage: "Missing data field specifications: {params}",
  },
  "import-code-table.tooltip": {
    id: "import-code-table.tooltip",
    defaultMessage:
      "Import the events of an exported code table into this code table",
  },
  overwrite: {
    id: "overwrite",
    defaultMessage: "Overwrite",
  },
  "overwrite-all": {
    id: "overwrite-all",
    defaultMessage: "Overwrite all",
  },
  skip: {
    id: "skip",
    defaultMessage: "Skip",
  },
  "skip-all": {
    id: "skip-all",
    defaultMessage: "Skip all",
  },
  "alarm-code-table.collision-dialog-title": {
    id: "alarm-code-table.collision-dialog-title",
    defaultMessage: "Event type with {code} code exists already",
  },
  "alarm-code-table.collision-dialog-description": {
    id: "alarm-code-table.collision-dialog-description",
    defaultMessage: "",
  },
  "missing-required-field": {
    id: "missing-required-field",
    defaultMessage: "Required field: {field}",
  },
  "tfk-import-customer": {
    id: "tfk-import-customer",
    defaultMessage: "Import customer data",
  },
  "alarm.firesignal.download-tfk-log": {
    id: "alarm.firesignal.download-tfk-log",
    defaultMessage: "Download tfk log",
  },
  "alarm.firesignal.tfk-required": {
    id: "alarm.firesignal.tfk-required",
    defaultMessage: "Required fields",
  },
  "alarm.firesignal.tfk-optional": {
    id: "alarm.firesignal.tfk-optional",
    defaultMessage: "Optional fields",
  },
  "tfk-zones-description": {
    id: "tfk-zones-description",
    defaultMessage: "The zones can be imported from the customers",
  },
  reports: {
    id: "reports",
    defaultMessage: "Reports",
  },
  "started-reports": {
    id: "started-reports",
    defaultMessage: "Started reports",
  },
  created: {
    id: "created",
    defaultMessage: "Created",
  },
  "job-groups": {
    id: "job-groups",
    defaultMessage: "Started reports",
  },
  "job-groups.edit-title": {
    id: "job-groups.edit-title",
    defaultMessage: "Report details",
  },
  "in-progress": {
    id: "in-progress",
    defaultMessage: "In progress",
  },
  "job-created-at": {
    id: "job-created-at",
    defaultMessage: "Started: {created, date, tstz}",
  },
  "result-expires": {
    id: "result-expires",
    defaultMessage: "Expires: {expires, date, tstz}",
  },
  "day-title": {
    id: "day-title",
    defaultMessage: "{day, date, textDate} ({count} jelentés)",
  },
  "report-title": {
    id: "report-title",
    defaultMessage: "{created, date, time} - {descr}",
  },
  "report-status": {
    id: "report-status",
    defaultMessage:
      "{status, select, success{Finished successfully at {changed, date, time}} failure{Stopped with error at {changed, date, time}} in_progress{In progress since {changed, date, time}...} pending{Waiting in queue since {changed, date, time}...}}",
  },
  retry: {
    id: "retry",
    defaultMessage: "Retry",
  },
  "start-the-report": {
    id: "start-the-report",
    defaultMessage: "Begin",
  },
  "regular-report-description": {
    id: "regular-report-description",
    defaultMessage: "Regular report ({start, date, tstz} - {end, date, tstz})",
  },
  "regular-reports-help-text": {
    id: "regular-reports-help-text",
    defaultMessage:
      "The <i>regular reports</i> contain the events in the specified interval in separate PDFs for each customer.<br /><br />You can change the included events on the <a href='/alarm/customers/list' target='_blank'>Edit customer</a> page: open / close events, or all events . If neither options is selected, then the customer will not appear in the regular reports.",
  },
  "report-delete-info": {
    id: "report-delete-info",
    defaultMessage: "The files are removed automatically after 5 days.",
  },
});
