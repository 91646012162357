import React, { useEffect, useCallback } from "react";
import { useMemo, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

import { makeStyles, Theme } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import CircularProgress from "@material-ui/core/CircularProgress";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { TextField } from "@mb-pro-ui/components/TextField";
import { Button } from "@mb-pro-ui/components/Button";

import logo from "../logo.svg";
import { colors } from "../theme";

import { useAuth } from "../auth";
import { useLocale } from "../locales/LocaleProvider";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: "100vh",
  },
  container: {
    backgroundColor: theme.palette.common.white,
    padding: "5%",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      borderRadius: "20px",
    },
  },
  title: {
    textTransform: "uppercase",
    fontWeight: "bold",
    "& > span": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
      marginBottom: "20px",
    },
    "@media (max-width:350px)": {
      display: "none",
    },
  },
  subtitle: {
    marginBottom: "4rem",
  },
  input: {
    margin: "0.2rem",
    width: "100%",
  },
  form: {
    width: "70%",
    marginTop: "2rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  link: {
    marginLeft: "auto",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "unset",
    },
  },
  button: {
    marginTop: "4rem",
    paddingRight: "4.5rem",
    paddingLeft: "4.5rem",
    fontWeight: theme.typography.fontWeightLight,
  },
  progress: {
    marginTop: "4rem",
  },
  localeButton: {
    "&:not(:first-child):before": {
      content: '"/"',
      paddingLeft: "0.2rem",
      paddingRight: "0.2rem",
    },
  },
  localization: {
    alignSelf: "flex-end",
    color: colors.menuText,
    position: "absolute",
    top: "30px",
    right: "30px",
    [theme.breakpoints.down("xs")]: {
      top: "10px",
      right: "10px",
    },
  },
  selectedLocale: {
    fontWeight: "bold",
  },
}));

type LoginLocationState = { from?: { pathname: string } } | undefined;
export interface LoginPageProps {}

const LoginPage = (props: LoginPageProps) => {
  const { locale, locales, setLocale } = useLocale();
  const intl = useIntl();

  useEffect(() => {
    document.title = "Login - Monitoringbook Pro";
  }, []);

  const classes = useStyles();

  const history = useHistory();
  const location = useLocation<LoginLocationState>();

  const from = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has("from")) {
      return JSON.parse(searchParams.get("from") ?? '"/home"');
    }
    return location.state?.from ?? { pathname: "/home" };
  }, [location.state, location.search]);

  const auth = useAuth();
  const { isLoading, isError, error } = auth.signin;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (auth.user) {
      const pathname = typeof from === "string" ? from : from.pathname;
      if (pathname.match(/^\/home/)) {
        history.replace(from);
      } else {
        const url = new URL(pathname, window.location.origin);
        if (typeof from !== "string") {
          if (from.search !== undefined) {
            url.search = from.search;
          }
          if (from.hash !== undefined) {
            url.hash = from.hash;
          }
        }
        window.location.replace(url.toString());
      }
    }
  }, [auth.user, history, from]);

  const handleSubmit = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      auth.signin.mutate({ username: username, password });
    },
    [auth.signin, username, password]
  );

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const errorMessage = useMemo(() => {
    if (error?.status === 401) {
      return intl.formatMessage({
        id: "login.invalidLogin",
        defaultMessage: "Invalid username or password",
      });
    } else if (error) {
      return intl.formatMessage(
        {
          id: "login.serverError",
          defaultMessage: "Could not log in: {message}",
        },
        { message: error.message }
      );
    } else {
      return "\u00a0";
    }
  }, [error, intl]);

  return (
    <Grid
      container
      direction="column"
      alignContent="center"
      alignItems="center"
      justify="center"
      className={classes.root}
    >
      <Container maxWidth="md" className={classes.container}>
        <Grid
          container
          direction="column"
          alignContent="center"
          alignItems="center"
        >
          <Typography className={classes.localization}>
            {locales.map((lang) => (
              <button
                className={`${classes.localeButton} ${
                  locale === lang ? classes.selectedLocale : ""
                }`}
                key={lang}
                onClick={() => setLocale(lang)}
                disabled={locale === lang}
              >
                {lang}
              </button>
            ))}
          </Typography>
          <img src={logo} alt="" width="64px" height="73px"></img>
          <Typography className={classes.title} variant="h1">
            <span>Monitoringbook</span> Pro
          </Typography>
          <Hidden xsDown>
            <Typography className={classes.subtitle} variant="subtitle1">
              <FormattedMessage
                id="login.subtitle"
                defaultMessage="Please login below to proceed"
              />
            </Typography>
          </Hidden>
          <form
            className={classes.form}
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Grid
              container
              direction="column"
              alignContent="center"
              alignItems="center"
            >
              <TextField
                className={classes.input}
                type="text"
                placeholder={intl.formatMessage({
                  id: "login.username",
                  defaultMessage: "Username",
                })}
                value={username}
                onChange={handleUsernameChange}
                autoFocus
                required
                error={isError}
              ></TextField>
              <TextField
                className={classes.input}
                type={showPassword ? "text" : "password"}
                value={password}
                placeholder={intl.formatMessage({
                  id: "login.password",
                  defaultMessage: "Password",
                })}
                onChange={handlePasswordChange}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        data-testid="toggle-visibility"
                      >
                        {showPassword ? (
                          <Visibility color="primary" />
                        ) : (
                          <VisibilityOff color="primary" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={isError}
                helperText={isError ? errorMessage : "\u00a0"}
              />
              {isLoading ? (
                <CircularProgress
                  data-testid="loading-spinner"
                  className={classes.progress}
                  size={42} // height of Button size="large"
                />
              ) : (
                <Button className={classes.button} size="large" type="submit">
                  <FormattedMessage id="login.login" defaultMessage="Login" />
                </Button>
              )}
            </Grid>
          </form>
        </Grid>
      </Container>
    </Grid>
  );
};

export default LoginPage;
