import {
  useMemo,
  createContext,
  useContext,
  PropsWithChildren,
} from "react";

import { useUserInfo } from "../state/user-info";
import { http, httpWithAuth } from "./http";

type Api = <T>(path: string, options?: RequestInit) => Promise<T>;

const ApiContext = createContext<Api | undefined>(undefined);

export type ApiProvderProps = PropsWithChildren<{ baseUrl: string }>;

export const ApiProvider = ({ baseUrl, children }: ApiProvderProps) => {
  const [token] = useUserInfo();

  const api = useMemo(() => {
    if (token) {
      return <T,>(path: string, options?: RequestInit) =>
        httpWithAuth<T>(token, `${baseUrl}${path}`, options);
    } else {
      return <T,>(path: string, options?: RequestInit) =>
        http<T>(`${baseUrl}${path}`, options);
    }
  }, [token, baseUrl]);

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

export const useApi = () => {
  const api = useContext(ApiContext);
  if (api === undefined) {
    throw new Error("useApi must be used within an ApiProvider");
  }
  return api;
};
